import React, { Fragment, useState, useContext, useEffect } from 'react'
import SyncClinicAddress from '../syncComponents/SyncAddress'
import ApplyCustomerAndLicense from './ApplyCustomerAndLicense'
import StateContext from '../MemberContext'
import { SelectCustomer, AppointmentTypesSycle, AppointmentTypeSyclePro, AppointmentTypeBlueprint, SelectEndpointsField } from '../syncComponents/helpers'
import { useSelector } from 'react-redux'
import useFetchLoginToken from '../../hooks/useFetchLoginToken'
import { MemberActionContext } from '../../containers/MemberList'
import CloseIcon from '@material-ui/icons/Close';
import { Button} from '@material-ui/core';

const UpdateBlueprintMember = ({memberInfo, handleInputChange}) => {
    return(
        <Fragment> <div className="c-form__form-group">
                <label> Api key </label>
                <input 
                    type="text" 
                    name="apiKey" 
                    className="form-control" 
                    onChange={handleInputChange} 
                    value={memberInfo.apiKey} 
                />
            </div>
            <div className="c-form__form-group">
                <label> address street 1 </label>
                <input 
                    type="text" 
                    name="street1" 
                    className="form-control" 
                    onChange={handleInputChange} 
                    value={memberInfo.street1} 
                />
            </div>
            <div className="c-form__form-group">
                <label> address street 2 </label>
                <input 
                    type="text" 
                    name="street2" 
                    className="form-control" 
                    onChange={handleInputChange} 
                    value={memberInfo.street2} 
                />
            </div>
            <div className="c-form__form-group">
                <label> City </label>
                <input 
                    type="text" 
                    name="city" 
                    className="form-control" 
                    onChange={handleInputChange} 
                    value={memberInfo.city} 
                />
            </div>
            <div className="c-form__form-group">
                <label> State </label>
                <input 
                    type="text" 
                    name="state" 
                    className="form-control" 
                    onChange={handleInputChange} 
                    value={memberInfo.state} 
                />
            </div>
            <div className="c-form__form-group">
                <label> Zip </label>
                <input 
                    type="text" 
                    name="zip" 
                    className="form-control" 
                    onChange={handleInputChange} 
                    value={memberInfo.zip} 
                />
            </div>
            <div className="c-form__form-group">
                <label> Phone </label>
                <input 
                    type="text" 
                    name="phone" 
                    className="form-control" 
                    onChange={handleInputChange} 
                    value={memberInfo.phone || memberInfo.phone1} 
                />
            </div>
        </Fragment>
    )
}

const UpdateSycleMember = ({memberInfo, handleInputChange}) =>  {
    return (
        <Fragment>
            <div className="c-form__form-group">
                <label>Username</label>
                <input 
                    type="text" 
                    name="username" 
                    className="form-control" 
                    onChange={handleInputChange} 
                    value={memberInfo.username} 
                />
            </div>
            <div className="c-form__form-group">
                <label>Password</label>
                <input 
                    type="text" 
                    name="password" 
                    className="form-control" 
                    onChange={handleInputChange} 
                    value={memberInfo.password} 
                />
            </div>
            <SyncClinicAddress handleInputChange={handleInputChange} />
        </Fragment>
    )
}

const UpdateMember = ({member, onCloseModal, onClose}) => {
    const customers = useSelector(state => state.customers.items)
    const { onDeleteMember, onUpdateMember, onUpdateLicense} = useContext(MemberActionContext)
    const [memberInfo, setMemberInfo] = useState({
        ...member,
        parent_company_id: member.parent_company_id ? member.parent_company_id : null,
        appointment_type_id_new_patient:         member.appointment_type_id_new_patient ? member.appointment_type_id_new_patient : null,
        appointment_type_id_returning_patient:   member.appointment_type_id_returning_patient ? member.appointment_type_id_returning_patient : null,
        appointment_type_name_new_patient:       member.appointment_type_name_new_patient ? member.appointment_type_name_new_patient : null,
        appointment_type_name_returning_patient: member.appointment_type_name_returning_patient ? member.appointment_type_name_returning_patient : null,
        nextslot_endpoint: member.nextslot_endpoint ? member.nextslot_endpoint : "/v1/"
    })

  const { token } = useFetchLoginToken({
    memberInfo
  })

    const handleSubmit = (e) => {
        e.preventDefault();
        onUpdateMember(memberInfo);
        //put licenses and  back to pool
        if ( (member.customer._id !== memberInfo.customer_id) && member.license_id) {
            onUpdateLicense({
                _id: member.license_id,
                active: false
            })
        }
        onCloseModal && onCloseModal();
        onClose()
    }

    const handleDelete = (e) => {
        e.preventDefault();
        onDeleteMember(memberInfo._id);
        onCloseModal && onCloseModal();
        onClose()
    }

    const handleInputChange = (e) => {
        e.preventDefault();
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        setMemberInfo({...memberInfo, [name]: value})
    }

    const handleCustomerChange = (e) => {
        e.preventDefault();
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        if (value !== member.customer._id) {
            setMemberInfo({...memberInfo, license_id: null, [name]: value, license: null});
        }
    }

    // useEffect(() => {
    //     console.log(memberInfo)
    // },[memberInfo])


    return (
        <StateContext.Provider value={{memberInfo, setMemberInfo, token}}>
            <Fragment>
                { !member.customer && 
                    <ApplyCustomerAndLicense 
                        member={member} 
                        onCloseModal={onCloseModal} 
                        onUpdateMember={onUpdateMember} 
                        onUpdateLicense={onUpdateLicense} 
                    /> 
                }
                { member.customer && <form onSubmit={handleSubmit} className="c-form" style={{padding: "2rem"}}>
                    <div className="c-form__form-group">
                      <Button onClick={() => onClose()} color="primary" startIcon={<CloseIcon />}> Close </Button> 
                    </div>
                    <div className="c-form__form-group">
                        <label>Clinic Name</label>
                        <input 
                            type="text" 
                            name="clinic_name" 
                            className="form-control" 
                            onChange={handleInputChange} 
                            value={memberInfo.clinic_name} 
                        />
                    </div>
                    <div className="c-form__form-group">
                        <label>Account Number</label>
                        <input 
                            type="text" 
                            name="account_number" 
                            className="form-control" 
                            onChange={handleInputChange} 
                            value={memberInfo.account_number} 
                        />
                    </div>
                    <div className="c-form__form-group">
                        <label>Clinic ID</label>
                        <input 
                            type="text" 
                            name="clinic_id" 
                            className="form-control" 
                            onChange={handleInputChange} 
                            value={memberInfo.clinic_id} 
                        />
                    </div>
                    <SelectEndpointsField />
                    {customers && 
                      <SelectCustomer 
                        customers={customers} 
                        onChangeCustomer={handleCustomerChange} 
                        defaultValue={member.customer ? member.customer._id : "0"} 
                      />
                    }
                    {memberInfo.nextslot_endpoint !== '/blueprint/' && <UpdateSycleMember memberInfo={memberInfo} handleInputChange={handleInputChange} />}
                    {memberInfo.nextslot_endpoint === '/blueprint/' && <UpdateBlueprintMember memberInfo={memberInfo} handleInputChange={handleInputChange} />}
                    <div className="c-form__form-group">
                        <label>Email</label>
                        <input 
                            type="text" 
                            name="email" 
                            className="form-control" 
                            onChange={handleInputChange} 
                            value={memberInfo.email} 
                        />
                    </div>
                    <div className="c-form__form-group">
                        <label>Notification Emails</label>
                        <input 
                            type="text" 
                            name="notification_emails" 
                            className="form-control" 
                            onChange={handleInputChange} 
                            value={memberInfo.notification_emails} 
                        />
                    </div>
                    {memberInfo.nextslot_endpoint === '/blueprint/' &&
                        <div className="c-form__form-group">
                            <label>Clinic Timezone</label>
                            <input 
                                type="text" 
                                name="clinic_timezone" 
                                className="form-control" 
                                onChange={handleInputChange} 
                                value={memberInfo.clinic_timezone} 
                            />
                        </div>
                        }
                    <div className="c-form__form-group">
                        <label>Thank You Message</label>
                        <textarea 
                            type="text" 
                            name="thank_you_message" 
                            className="form-control" 
                            rows="3"
                            onChange={handleInputChange} 
                            value={memberInfo.thank_you_message} 
                        ></textarea>
                    </div>
                    <div className="c-form__form-group">
                        <label>Patient Email Message</label>
                        <textarea 
                            type="text" 
                            name="patient_email_message" 
                            className="form-control" 
                            rows="3"
                            onChange={handleInputChange} 
                            value={memberInfo.patient_email_message} 
                        ></textarea>
                    </div>
                    <input 
                        name="customer_id" 
                        value={memberInfo.customer_id}
                        className="form-control hidden"
                        disabled
                    />
                    {memberInfo.parent_company_id && <AppointmentTypeSyclePro />}
                    {memberInfo.nextslot_endpoint === '/v1/' && <AppointmentTypesSycle /> }
                    {memberInfo.nextslot_endpoint === '/blueprint/' && <AppointmentTypeBlueprint /> }
                    <div className="c-form__controls o-flex">
                        <button type="submit" className="c-btn">Update</button>
                        <button className="c-btn c-btn--warn" onClick={handleDelete}>Delete</button>
                    </div>
                </form>
            }
            </Fragment>
        </StateContext.Provider>
    )
}

export default UpdateMember;
