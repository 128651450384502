import { 
    ADD_MEMBER_SUCCESS, 
    UPDATE_MEMBER_SUCCESS, 
    DELETE_MEMBER_SUCCESS,
    REQUEST_MEMBERS,
    FETCH_MEMBERS_SUCCESS,
    FETCH_MEMBERS_FAIL
} from '../actions/member';


const members = (
    state = {
        isFetching: false,
        items: [],
        lastUpdated: '',
    }, 
    action
) => {
    switch (action.type) {
        case ADD_MEMBER_SUCCESS:
            return {
                ...state,
                items: [
                    ...state.items, 
                    {
                        ...action.member
                    }
                ]
            }
            
        case UPDATE_MEMBER_SUCCESS:
            return {
                ...state,
                items: state.items.map(member => 
                    (member._id === action.member._id)
                        ? action.member
                        : member
                    ),
                lastUpdated: action.receivedAt
            }
            
        case DELETE_MEMBER_SUCCESS:
            return { 
                ...state, 
                items: state.items.filter(member => member._id !== action.id ),
                lastUpdated: action.receivedAt
            }

        case REQUEST_MEMBERS:
            return {
                ...state, 
                isFetching: true,
            }

        case FETCH_MEMBERS_SUCCESS:
            return {
                ...state, 
                isFetching: false,
                items: action.members,
                lastUpdated: action.receivedAt
            }
        case FETCH_MEMBERS_FAIL:
            return {
                ...state, 
                isFetching: false,
            }
        default:
            return state
    }
}
export default members

