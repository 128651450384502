import React, { useEffect } from 'react';
import { connect } from 'react-redux'
import { Route, Redirect, withRouter } from 'react-router-dom'


const PrivateRoute = ({ component: Component, setLoggedIn, isAuthenticated, ...rest }) => {
    useEffect(() => {
        if(setLoggedIn)
            setLoggedIn(isAuthenticated)
    }, [isAuthenticated])

    return(
        <Route
            {...rest}
            render={props =>
                isAuthenticated ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: { from: props.location }
                        }}
                    />
                )
            }
        />
    )
};

const mapStateToProps = (state, ownProps) => {
    return {isAuthenticated: state.auth.isAuthenticated, setLoggedIn: ownProps.setLoggedIn}
}

export default withRouter(connect(mapStateToProps, null, null, {pure: false})(PrivateRoute)); 
