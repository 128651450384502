import React, {useState, Fragment} from "react"
import { Modal } from '../modal/ModalWrapper';
import { updateMember } from '../../actions/member'
import { updateLicense } from '../../actions/license'
import { useSelector, useDispatch } from 'react-redux'
import { FilterMembersByCustomer } from '../syncComponents/helpers'
import  {selectLicense} from './ApplyCustomerAndLicense'
import { Grid, Typography } from "@material-ui/core"
import {Button } from "@material-ui/core"

const ApplyMultipleLicenses = ({customers, members, licenses, onUpdateMember, onUpdateLicense}) =>{
    const [filtered_members, setMembers] = useState(null)

    return(
        <Fragment>
          <FilterMembersByCustomer customers={customers} members={members} setMembers={setMembers} />
          {filtered_members && <ApplyLicensesModal customers={customers} members={members}  noModal={true} /> }
        </Fragment>
    )
}

const ApplyLicensesModal = ({members, noModal, displayBtn}) => {
  const dispatch = useDispatch()
  const licenses = useSelector(state => state.licenses.items)
  const [open, setOpen] = useState(false)

  const onApplyMultipleLicenses = () => {
    let new_licenses = licenses
    members.map(member => {
      let license_id = selectLicense(new_licenses, member)
      new_licenses = new_licenses.filter( ({_id}) => _id !== license_id)
      dispatch(updateMember({...member, license_id}))
      dispatch(updateLicense({
        _id: license_id,
        active: true
      }))
    })
  }

  const Wrapper = ({modal, children})  =>  !modal ? <Modal title="Adding multiple licenses" onClose={() => setOpen(false)}> { children} </Modal> : <Fragment> { children} </Fragment>
  return(
    <Fragment>
      {displayBtn && <Button onClick={() => setOpen(true)} color="primary">Apply Licenses</Button> }
      {displayBtn && open && 
        <Wrapper modal={noModal ? "false" : noModal}>
          <Grid container>
            <Grid item xs={12}> <Typography> Adding ids to {members.length} members </Typography> </Grid>
            <Grid item> <button onClick={onApplyMultipleLicenses} className="c-btn">Apply</button> </Grid>
          </Grid>
        </Wrapper>
      }
      {!displayBtn && 
        <Wrapper modal={noModal ? "false" : noModal}>
          <Grid container>
            <Grid item xs={12}> <Typography> Adding ids to {members.length} members </Typography> </Grid>
            <Grid item> <button onClick={onApplyMultipleLicenses} className="c-btn">Apply</button> </Grid>
          </Grid>
        </Wrapper>
      }
    </Fragment>
  )
}

export default ApplyMultipleLicenses
export {
  ApplyLicensesModal
}
