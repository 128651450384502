import { 
    ADD_CUSTOMER_SUCCESS, 
    UPDATE_CUSTOMER_SUCCESS,
    DELETE_CUSTOMER_SUCCESS,
    REQUEST_CUSTOMERS,
    FETCH_CUSTOMERS_SUCCESS,
    FETCH_CUSTOMERS_FAIL
} from '../actions/customer';


const customers = (
    state = {
        isFetching: false,
        items: [],
        lastUpdated: '',
    }, 
    action
) => {
    switch (action.type) {
        case ADD_CUSTOMER_SUCCESS:
            return {
                ...state,
                items: [
                    ...state.items, 
                    {
                        ...action.customer
                    }
                ]
            }
            
        case UPDATE_CUSTOMER_SUCCESS:
            return {
                ...state,
                items: state.items.map(customer => 
                    (customer._id === action.customer._id)
                        ? action.customer
                        : customer
                    ),
                lastUpdated: action.receivedAt
            }
        
        case DELETE_CUSTOMER_SUCCESS:
            return { 
                ...state, 
                items: state.items.filter(customer => customer._id !== action.id ),
                lastUpdated: action.receivedAt
            }

        case REQUEST_CUSTOMERS:
            return {
                ...state, 
                isFetching: true,
            }

        case FETCH_CUSTOMERS_SUCCESS:
            const reduceArr = [...state.items, ...action.customers];
            const reduceObj = reduceArr.reduce((acc, curItem, index, reduceArr) => {
                if(acc.ids.indexOf(curItem._id) > -1) {
                    return {
                        ids: [...acc.ids],
                        entities: [...acc.entities]
                    }
                } else {
                    return {
                        ids: [...acc.ids, curItem._id],
                        entities: [...acc.entities, curItem]
                    }
                }
            }, {
                ids: [],
                entities: []
            });
            return {
                ...state, 
                isFetching: false,
                items: [...reduceObj.entities],
                lastUpdated: action.receivedAt
            }

        case FETCH_CUSTOMERS_FAIL:
            return {
                ...state, 
                isFetching: false,
            }

        
        default:
            return state
    }
}
export default customers

