import { 
  REQUEST_CLINICS ,
  FETCH_CLINICS_SUCCESS,
  SYNC_CLINICS
} from '../actions/clinic'

const clinics = ( state = { isFetching: false, items: [], lastUpdated: '', syncType: ''},  action) => {
  switch (action.type) {
    case REQUEST_CLINICS: 
      return {
        ...state, 
        isFetching: true
      }
    case FETCH_CLINICS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        items: action.clinics,
        lastUpdated: action.receivedAt
      }
    case SYNC_CLINICS:
      return {
        ...state,
        syncType: action.syncType
      }
    default:
      return state
  }
}

export default clinics