import React, {Fragment, useState, useEffect, memo, useRef } from "react"
import useClinicData from '../hooks/useClinicData'
import { useDispatch, useSelector } from 'react-redux'
import {Divider, Paper, FormControl, InputLabel, Select,  Grid, Typography, Button } from '@material-ui/core'
import { updateMember, fetchMembers } from '../actions/member'
import {syncClinics, setClinicAppointment} from '../actions/clinic'
import { TextField } from '@material-ui/core'
import useFetchLoginToken from '../hooks/useFetchLoginToken'
import CircularProgress from '@material-ui/core/CircularProgress';

const CardLayout = ({title, children}) => {
  return (
    <Paper elevation={3} style={{padding: "1.4rem"}}>
      <Grid container spacing={2}>
        {/******************************* TITLE *****************************/}
        <Grid item xs={12}>
          <Typography variant="subtitle1" style={{fontWeight: "700", paddingBottom: ".4rem" }}>{title}</Typography>
          <Divider />
        </Grid>
        {children}
      </Grid>
    </Paper>
  )
}

const GlobalApptUpdate = ({label, token, onApply}) => {
  const { clinicsLoading, getApptTypes } = useClinicData(null, token)
  const [apptTypes, setApptTypes] = useState(false)
  const [values, setValues] = useState({
    name: null,
    length: null,
    selectValue: null,
  })

  useEffect( () => {
    if(!clinicsLoading) {
      setApptTypes(getApptTypes('all'))
    }
  }, [clinicsLoading])

  const onApplyApptTypes = () => {
    onApply(values)
  }

  const onChangeValue = (l,v) => {
    setValues(values => ({...values, [l]: v}))
  }

  return(
    <Grid item xs={6}>
      <CardLayout title={"Appointment Types Global Edit " + label} >
        <GridTextField label="Custom: Fallback Name" value={values.name} onChange={(l, v) => onChangeValue("name", v)} />
        <GridTextField label="Custom: Fallback Length" value={values.length}  onChange={(l, v) => onChangeValue("length", v)}/>
        <GridSelectField
          label={label + " patient"}
          onChange={(l ,v ) => onChangeValue("selectValue", v)}
          jsonOptions={JSON.stringify([...Object.values(apptTypes).map(v => v)].flat(1))}
        />
        <Button onClick={onApplyApptTypes} variant="contained" color="primary"> Apply to all</Button>
      </CardLayout>
    </Grid>
  )
}

const FetchingLoader = ({isLoading, text, size}) => {
  return(
    <Fragment>
      {isLoading && <Grid item xs={12}>
          <Grid container spacing={3}  alignItems="center">
            <Grid item> <Typography variant="body1" style={{color: "grey"}}> {text} </Typography> </Grid>
            <Grid item> <CircularProgress size={size? size : 30} /> </Grid>
          </Grid>
        </Grid>
      }
    </Fragment>
  )
}

function areEqual({member: prevMember, token: prevToken, apptTypes: prevApptTypes}, {member: nextMember, token: nextToken, apptTypes: nextApptTypes}) {
  return JSON.stringify(prevMember) === JSON.stringify(nextMember) &&
    JSON.stringify(prevToken) === JSON.stringify(nextToken) &&
    JSON.stringify(prevApptTypes) === JSON.stringify(nextApptTypes)
}

const GridTextField = memo(({label, value, onChange}) => {
  const [fieldVal, setField] = useState(value)

  useEffect(() => {
    if (value !== fieldVal) {
      setField(value)
    }
  }, [value])

  const onChangeField = e => {
    let value = e.target.value
    setField(value)
    onChange(label, value)
  }

  return(
    <Grid item>
        <TextField label={label} value={fieldVal} onChange={onChangeField} variant="outlined" />
    </Grid>
  )
}, (prevProps, nextProps) => prevProps.value === nextProps.value)

const GridSelectField = ({label, jsonOptions, value, onChange, options}) => {
  const inputLabel = useRef(null);
  const [selectVal, setVal] = useState(value)

  useEffect(() => {
    if (value !== selectVal) {
      setVal(value)
    }
  }, [value])

  const onChangeField = e => {
    let value = e.target.value
    setVal(value)
    onChange(label, value)
  }

  const custom_field = value && JSON.parse(value).id === 'custom'
  return(
    <Grid item>
      <FormControl variant="outlined" style={{"minWidth": "220px"}}>
          <InputLabel ref={inputLabel} htmlFor={label+"-type"}> {label} </InputLabel>
          <Select
              native
              value={custom_field ? 'custom' : selectVal}
              onChange={onChangeField}
              labelWidth={inputLabel.current ? inputLabel.current.offsetWidth : 0}
              inputProps={{
                  name: label,
                  id: label+'-type',
              }}
            >
              <option key="custom" value="custom"> Custom </option>
              {options && options.map( value =>  <option key={value.value} value={value.value}>{value.label}</option> )}
              {jsonOptions && JSON.parse(jsonOptions).map( value =>  <option key={value.value} value={value.value}>{value.label}</option> )}
          </Select>
      </FormControl>
    </Grid>
  )
}


const create_appt_type_schema = (type, values) => JSON.stringify({
  name: values['appointment_type_name_' + type + '_patient'],
  id: values['appointment_type_id_' + type + '_patient'],
  length: Number(values['appointment_type_length_' + type + '_patient'])
})

const UpdateMemberRowNoMemo = ({ dispatch, member, token, apptTypesNew, apptTypesReturning }) => {
  const [memberInfo, setMemberInfo] = useState(member)
  const [apptTypes, setApptTypes] = useState(null)
  const { clinicsLoading, getApptTypes, getClinicInfo } = useClinicData(null, token, member)
  const [ loading, setLoading] = useState(false)
  const syncType = useSelector(state => state.clinics.syncType)

  const onChangeMember = (field, value) => {
    setMemberInfo( memberInfo => ({...memberInfo, [field]: value}))
  }

  const onGlobalApptChange = (appt, type) => {
    if(!appt || !apptTypes)
      return
    const {name: fallBackName, length: fallBackLength, selectValue } = appt
    try {
      let valuesToApply = null
      //check if value exist in appointment types
      for(const {label, value} of apptTypes) {
        if(value === selectValue) {
          valuesToApply = selectValue
          break
        }
      }
      if(!valuesToApply) { //value did not exist set a custom id
        valuesToApply = JSON.stringify({name: fallBackName, id: 'custom', length: fallBackLength })
      }
      onChangeApptType(valuesToApply, type)
    } catch(err) {
      console.log(err)
      debugger
    }
  }

  useEffect( () => {
    if(!clinicsLoading) {
      setApptTypes(getApptTypes(memberInfo.clinic_id))
    }
  }, [clinicsLoading])

  useEffect( () => {
    onGlobalApptChange(apptTypesNew, "new")
  }, [apptTypesNew])

  useEffect( () => {
    onGlobalApptChange(apptTypesReturning, "returning")
  }, [apptTypesReturning])

  useEffect( () => {
    if(syncType !== '') {
      if(syncType !== 'save') {
        onSync(syncType)
      } else {
        onUpdateMember()
      }
    }
  },[syncType])

  const onSync = async(type) => {
    setLoading(true)
    getClinicInfo(memberInfo, type, token.tokens[memberInfo.username]).then( info => {
      setMemberInfo({...memberInfo, ...info })
      setLoading(false)
    })
  }

  const onUpdateMember = () => {
    setLoading(true)
    dispatch(updateMember(memberInfo))
    setLoading(false)
  }
  const {clinic_id, clinic_name, street1, street2, city, state, zip, phone} = memberInfo
  const {
    appointment_type_id_new_patient,
    appointment_type_name_new_patient,
    appointment_type_length_new_patient,
    appointment_type_id_returning_patient,
    appointment_type_name_returning_patient,
    appointment_type_length_returning_patient,
  } = memberInfo

  let new_appt_type = create_appt_type_schema('new', {
    appointment_type_id_new_patient,
    appointment_type_name_new_patient,
    appointment_type_length_new_patient,
  })

  let returning_appt_type = create_appt_type_schema('returning', {
    appointment_type_id_returning_patient,
    appointment_type_name_returning_patient,
    appointment_type_length_returning_patient,
  })

  const onChangeApptType = (value, type) => {
    let { name, id, length } = JSON.parse(value)
    setMemberInfo({
      ...memberInfo,
      ['appointment_type_id_' + type + '_patient']: id,
      ['appointment_type_name_' + type + '_patient']: name,
      ['appointment_type_length_' + type + '_patient']: length,
    })
  }

  return(
    <Grid item xs={12}>
      <CardLayout title={clinic_id + ': ' + clinic_name}>
        <FetchingLoader isLoading={loading} text="Syncing..." />

        {/******************************* CLINIC FIELDS *****************************/}
        <Grid item xs={12} >
          <Grid container spacing={3}>
            <GridTextField label="clinic_name" value={clinic_name} onChange={onChangeMember}/>
            <GridTextField label="street1" value={street1} onChange={onChangeMember} />
            <GridTextField label="street2" value={street2} onChange={onChangeMember} />
            <GridTextField label="city" value={city} onChange={onChangeMember} />
            <GridTextField label="state" value={state} onChange={onChangeMember} />
            <GridTextField label="zip" value={zip}  onChange={onChangeMember} />
            <GridTextField label="phone" value={phone} onChange={onChangeMember}  />
            <FetchingLoader isLoading={!apptTypes} text="Fetching Appointment types..." size={15} />
            {apptTypes && <GridSelectField value={new_appt_type} label="Appointment Type new" options={apptTypes} onChange={(l, v) => onChangeApptType(v, 'new')} />}
            {apptTypes && <GridSelectField value={returning_appt_type} label="Appointment Type returning" options={apptTypes} onChange={(l, v) => onChangeApptType(v, 'returning')} />}
          </Grid>
        </Grid>

        {/******************************* CLINIC BUTTONS *****************************/}
        <Grid item xs={12}>
          <Grid container spacing={5}>
            <Grid item> <Button onClick={onUpdateMember} variant="contained"> Save </Button> </Grid>
            <Grid item> <Button onClick={() =>onSync("name")} variant="contained"> Update Name </Button> </Grid>
            <Grid item> <Button onClick={() =>onSync("address")} variant="contained"> Update Address </Button> </Grid>
            <Grid item> <Button onClick={() =>onSync("all")} variant="contained"> Update All </Button> </Grid>
          </Grid>
        </Grid>
      </CardLayout>
    </Grid>
  )
}

const UpdateMemberRow = memo(UpdateMemberRowNoMemo)

const UpdateMembersPage = ({match: { params}}) => {
  let ids = localStorage.getItem('syncClinicIds') || [];

  const dispatch = useDispatch()
  const [members, db_members] = useSelector(state => {
    let customers = {}
    state.customers.items.map( (customer, idx) => {
      customers[customer._id] = idx
    })
    let licenses = {}
    state.licenses.items.map( (license, idx) => {
      licenses[license._id] = idx
    })
    let db_members =  state.members.items.filter(member => ids.includes(member.clinic_id)).map( member => ({
      ...member,
      customer: state.customers.items[customers[member.customer_id]],
      license: state.licenses.items[licenses[member.license_id]]
    }))
    return [Object.assign({}, ...db_members.map(member => ({[member.clinic_id]: member}))), db_members]
  })

  const {token, fetching, fetchMultipleTokens} = useFetchLoginToken({memberInfo: null})
  const [apptTypesNew, setApptNew] = useState(null)
  const [apptTypesReturning, setApptReturning] = useState(null)

  useClinicData(members, token)

  useEffect( () => {
    dispatch(fetchMembers())
  }, [])


  useEffect( () => {
    if(db_members && db_members.length !== 0 && !token.tokens) {
      fetchMultipleTokens(db_members)
    }
  }, [token, db_members])

  const onSync = type => {
    dispatch(syncClinics(type))
  }

  if(ids.length === 0)
    return <div> Error. No ids go back to members </div>

  return(
    <Grid container spacing={5}>
      {!token.tokens && <Grid item xs={12}><Typography variant="body1">Fetching Tokens... </Typography> <CircularProgress size={30}/> </Grid>}
      <Grid item xs={12}>
        <Grid container direction="row" justify="space-between" alignItems="center" style={{paddingTop: "20px", paddingBottom: "20px"}}>
          <Grid item> <Typography variant="h5">Syncing {db_members.length} Members with Sycle API</Typography> </Grid>
          <Grid item>
            <Grid container spacing={3}>
              <Grid item><Button onClick={() => onSync('all')} color="primary" variant="contained">Sync Everything</Button></Grid>
              <Grid item><Button onClick={() => onSync('address')} color="primary" variant="contained">Sync Addresss</Button></Grid>
              <Grid item><Button onClick={() => onSync('name')} color="primary" variant="contained">Sync Name</Button></Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/******************************* Appointment Types  *****************************/}
      <GlobalApptUpdate label="New" token={token} onApply={(v) => setApptNew(v)}/>
      <GlobalApptUpdate label="Returning" token={token} onApply={(v) => setApptReturning( v)}/>

      {/******************************* CLINICS  *****************************/}
      {members && Object.values(members).map(member =>
          <UpdateMemberRow
            dispatch={dispatch}
            member={member}
            members={members}
            key={member.clinic_id}
            token={token}
            apptTypesNew={apptTypesNew}
            apptTypesReturning={apptTypesReturning}
          />
      )}
      <Grid item xs={12}> <Button onClick={() => onSync('save')} variant="contained"> Save All</Button></Grid>
    </Grid>
  )
}

export default UpdateMembersPage
