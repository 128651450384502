import { fetchClinicInfo } from '../hooks/useClinicData'

/*
* Clinic Actions
*/

export const REQUEST_CLINICS = 'REQUEST_CLINICS';
export const FETCH_CLINICS_SUCCESS = 'FETCH_CLINICS_SUCCESS';
export const FETCH_CLINICS_FAIL = 'FETCH_CLINICS_FAIL';
export const SYNC_CLINICS = 'SYNC_CLINIC'

export const syncClinics = (type) => {
  return {
    type: SYNC_CLINICS,
    syncType: type
  }
}

export const fetchClinicsSuccess = (clinics) => {
  return {
      clinics,
      type: FETCH_CLINICS_SUCCESS,
      receivedAt: Date.now()
  }
}
/********************************
 * Fetch Clinics from sycle api 
********************************/
export const fetchClinics = (members, tokens) => {
  return (dispatch) => {
    dispatch(requestClinics())
    let fetching = async(dispatch, members, tokens) => {
      let clinics = {}
      let membersAppt = {}
      let res
      let value
      for(const member of Object.values(members)) {
        let info = await fetchClinicInfo(member, tokens[member.username], false, true)
        if(info.appt_types) {
          if(member.nextslot_endpoint === '/v1/') {
            membersAppt[member.clinic_id]= info.appt_types.map( appt => {
              value = JSON.stringify({ name: appt.name, id: appt.appt_type_id, length: appt.length })
              return { label: appt.name, value }
            })
          } else {
            membersAppt[member.clinic_id] = info.appt_types.map( ({appointmentName, appointmentLength, id}) => {
              value = JSON.stringify({ name: appointmentName, id: id.toString(), length: appointmentLength})
              return{ label: appointmentName, value}
            })
          }
        } else {
          membersAppt[member.clinic_id] = []
        }
        clinics[member.clinic_id] = info
      }
      dispatch(fetchClinicsSuccess([clinics, membersAppt]))
    }
    fetching(dispatch, members, tokens)
    return
  }
}

export const requestClinics = () => {
  return {
    type: REQUEST_CLINICS
  }
}
