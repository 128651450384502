import React, { Component } from 'react';
import ModalWrapper from './modal/ModalWrapper';
import AddLicenses from './modal/AddLicenses';
import DeleteLicense from './modal/DeleteLicense';

class ManageLicenses extends Component {

    render() {
        return (
            <div className="o-wrapper">
                
                <div className="o-flex">
                    <h2>{this.props.customer.name} Licenses</h2>
                    <ModalWrapper
                    title="Add Licenses"
                    buttonText="Add Licenses"
                    width={400}
                    showOk={false}
                    component={AddLicenses}
                    customerID={this.props.customer._id}
                    onAddLicenses={this.props.onAddLicenses}
                    />
                </div>
                <h3>License List</h3>
                <table className="c-table">
                    <thead>
                        <tr>
                            <th>License ID</th>
                            <th>License Activated</th>
                            <th>License Expired</th>
                            <th>Activation Date</th>
                            <th>Expiration Date</th>
                            <th>Delete License</th>
                        </tr>
                    </thead>
                    <tbody>
                        { this.props.customer.licenses.map(license => {
                            return (
                                <tr key={license._id} >
                                    <td><div>{license._id}</div></td>
                                    <td><div>{license.active.toString()}</div></td>
                                    <td><div>{license.expired.toString()}</div></td>
                                    <td><div>{license.activation_date}</div></td>
                                    <td><div>{license.expiration_date}</div></td>
                                    <td>
                                        <ModalWrapper
                                        title="Delete License"
                                        buttonText="Delete License"
                                        width={400}
                                        showOk={false}
                                        component={DeleteLicense}
                                        onDeleteLicense={this.props.onDeleteLicense} 
                                        license={license}
                                        />
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        )
    }
}

export default ManageLicenses;