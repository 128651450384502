import React, {Fragment, useState, useEffect, useContext} from 'react';
import ApplyCustomerAndLicense, {selectLicense} from './ApplyCustomerAndLicense'
import { useSelector } from 'react-redux'
import { MemberActionContext } from '../../containers/MemberList'

const ApplyLicense = ({ member, onCloseModal}) => {
    const licenses = useSelector(state => state.licenses.items)
    const { onUpdateMember, onUpdateLicense} = useContext(MemberActionContext)
    const [ memberInfo, setMemberInfo] = useState({})
    const [ addCustomerToMember, setAddCustomer] = useState(false)
    useEffect( () => {
        if (member.customer === undefined) {
            setAddCustomer(true)
        } else {
            setMemberInfo({
                ...member,
                license_id: selectLicense(licenses, member)
            })              
        }
    }, [])

    const handleSubmit = (e) => {
        e.preventDefault();
        onUpdateMember(memberInfo);
        onUpdateLicense({
            _id: memberInfo.license_id,
            active: true
        });
        onCloseModal();
    }

    return (
        <Fragment>
            { addCustomerToMember && <ApplyCustomerAndLicense member={member} onUpdateMember={onUpdateMember} onUpdateLicense={onUpdateLicense} onCloseModal={onCloseModal}  /> }
            { !addCustomerToMember && memberInfo.license_id &&
                <form onSubmit={handleSubmit} className="c-form">
                    <h3>Apply License #: {memberInfo.license_id}</h3>
                    <p>Applying this license to this clinic will use up one of {member.customer.name}'s available licenses.</p>
                    <div className="c-form__controls o-flex">
                        <button type="submit" className="c-btn">Update</button>
                    </div>
                </form>
            }
            { !addCustomerToMember && !memberInfo.license_id && <h2> This customer doesnt have any avaiable licenses. Add more licenses to this customer.</h2> }
        </Fragment>
    )
}

export default ApplyLicense;