import React, {useState, useContext} from 'react'
import StateContext from '../../MemberContext'
import AddSycleMember from './AddSycleMember'
import AddSycleProMember from './AddSycleProMember'
import AddBlueprintMember from './AddBlueprintMember'
import {SelectCustomer} from '../../syncComponents/helpers'
import { MemberActionContext } from '../../../containers/MemberList'
import { useSelector } from 'react-redux'

const AddMember = ({onCloseModal}) => {
    const { onAddMember } = useContext(MemberActionContext)
    const customers = useSelector(state => state.customers.items)
    const [memberInfo, setMemberInfo] = useState({
        apiKey: '',
        clinic_name: '',
        clinic_id: '',
        username: '',
        password: '',
        parent_company_id: null,
        appointment_type_id_new_patient: null,
        appointment_type_name_new_patient: null,
        appointment_type_id_returning_patient: null,
        appointment_type_name_returning_patient: null,
        license_id: null,
        api_endpoint: null,
        email: '',
        notification_emails: '',
        customer_id: '',
        street1: '',
        stret2: '',
        city: '',
        state: '',
        zip: '',
        phone: '',
        nextslot_endpoint: '/v1/'
    })

    const [ setup, setSetup] = useState('')

    const handleSubmit = (e, member) => {
        if(e)  {
            e.preventDefault();
        }
        if(member) {
            console.log("Submitting new member")
            onAddMember(member)
        }
        else {
            onAddMember(memberInfo)
            onCloseModal();
        }
    }

    const handleInputChange = (e) => {
        e.preventDefault();
        let target = e.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        let name = target.name;
        setMemberInfo({...memberInfo, [name]: value})
    }

    const handleSetupChange = (e) => {
        /*
        if(e.target.value === 'manually') {  // hot fix think of better solution
            setMemberInfo('/v1/')
        }*/
        if(e.target.value === 'blueprint') {
            setMemberInfo({...memberInfo, nextslot_endpoint: '/blueprint/'})
        }
        setSetup(e.target.value)
    }

    return (    
        <StateContext.Provider value={{memberInfo, setMemberInfo}}>
            <div className="mb30">
                <span> Sign up for Sycle Pro, Sycle, or Blueprint </span>
                <select 
                    name="setup_option" 
                    className="form-control"
                    onChange={handleSetupChange}
                    defaultValue="0"
                >
                    <option value="0" disabled>Choose Setup</option>
                    <option value="classic">Log into Sycle Classic</option>
                    <option value="login">Log into Sycle Pro</option>
                    <option value="blueprint">Log into Blueprint</option>
                    <option value="manually">Manually fill out</option>
                </select>
            </div>
            
            { setup === 'classic'   && <AddSycleMember handleSubmit={handleSubmit} handleInputChange={handleInputChange} customers={customers} /> }
            { setup === 'login'     && <AddSycleProMember handleSubmit={handleSubmit} handleInputChange={handleInputChange} customers={customers} /> }
            { setup === 'manually'  && <AddMemberManually handleSubmit={handleSubmit} handleInputChange={handleInputChange} customers={customers} /> }
            { setup === 'blueprint' && <AddBlueprintMember handleSubmit={handleSubmit} handleInputChange={handleInputChange} customers={customers} /> }
        </StateContext.Provider>
    )
}

const AddMemberManually = ({customers, handleSubmit, handleInputChange}) => {
    const {memberInfo, setMemberInfo} = useContext(StateContext);
    let currCustomers = customers || []

    return (
        <form onSubmit={handleSubmit} className="c-form">
            <div className="c-form__form-group">
                <label>Clinic Name</label>
                <input 
                    type="text" 
                    name="clinic_name" 
                    className="form-control" 
                    onChange={handleInputChange} 
                    value={memberInfo.clinic_name} 
                />
            </div>
            <SelectCustomer customers={customers} onChangeCustomer={handleInputChange} />
            <div className="c-form__form-group">
                <label>Sycle ID</label>
                <input 
                    type="text" 
                    name="clinic_id" 
                    className="form-control" 
                    onChange={handleInputChange} 
                    value={memberInfo.clinic_id} 
                />
            </div>
            <div className="c-form__form-group">
                <label>Account Number</label>
                <input 
                    type="text" 
                    name="account_number" 
                    className="form-control" 
                    onChange={handleInputChange} 
                    value={memberInfo.account_number} 
                />
            </div>
            <div className="c-form__form-group">
                <label>Sycle Endpoint</label>
                <input 
                    type="text" 
                    name="api_endpoint" 
                    className="form-control" 
                    onChange={handleInputChange} 
                    value={memberInfo.api_endpoint} 
                />
            </div>
            <div className="c-form__form-group">
                <label>Username</label>
                <input 
                    type="text" 
                    name="username" 
                    id="username"
                    className="form-control" 
                    onChange={handleInputChange} 
                    value={memberInfo.username} 
                />
            </div>
            <div className="c-form__form-group">
                <label>Password</label>
                <input 
                    type="text" 
                    id="password"
                    name="password" 
                    className="form-control" 
                    onChange={handleInputChange} 
                    value={memberInfo.password} 
                />
            </div>
            <div className="c-form__form-group">
                <label>Email</label>
                <input 
                    type="text" 
                    name="email" 
                    className="form-control" 
                    onChange={handleInputChange} 
                    value={memberInfo.email} 
                />
            </div>
            <div className="c-form__form-group">
                <label>Notification Emails</label>
                <input 
                    type="text" 
                    name="notification_emails" 
                    className="form-control" 
                    onChange={handleInputChange} 
                    value={memberInfo.notification_emails} 
                />
            </div>
            <div className="c-form__form-group">
                <label> address street 1 </label>
                <input 
                    type="text" 
                    name="street1" 
                    className="form-control" 
                    onChange={handleInputChange} 
                    value={memberInfo.street1} 
                />
            </div>
            <div className="c-form__form-group">
                <label> address street 2 </label>
                <input 
                    type="text" 
                    name="street2" 
                    className="form-control" 
                    onChange={handleInputChange} 
                    value={memberInfo.street2} 
                />
            </div>
            <div className="c-form__form-group">
                <label> City </label>
                <input 
                    type="text" 
                    name="city" 
                    className="form-control" 
                    onChange={handleInputChange} 
                    value={memberInfo.city} 
                />
            </div>
            <div className="c-form__form-group">
                <label> State </label>
                <input 
                    type="text" 
                    name="state" 
                    className="form-control" 
                    onChange={handleInputChange} 
                    value={memberInfo.state} 
                />
            </div>
            <div className="c-form__form-group">
                <label> Zip </label>
                <input 
                    type="text" 
                    name="zip" 
                    className="form-control" 
                    onChange={handleInputChange} 
                    value={memberInfo.zip} 
                />
            </div>
            <div className="c-form__form-group">
                <label> Phone </label>
                <input 
                    type="text" 
                    name="phone" 
                    className="form-control" 
                    onChange={handleInputChange} 
                    value={memberInfo.phone} 
                />
            </div>
            <div className="c-form__controls">
                <button type="submit" className="c-btn">Create</button>
            </div>
        </form>
    )

}

export default AddMember;
