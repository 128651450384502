import axios from 'axios';

/*
* Member Actions
*/

export const ADD_MEMBER_SUCCESS = 'ADD_MEMBER_SUCCESS';
export const UPDATE_MEMBER_SUCCESS = 'UPDATE_MEMBER';
export const DELETE_MEMBER_SUCCESS = 'DELETE_MEMBER';
export const REQUEST_MEMBERS = 'REQUEST_MEMBERS';
export const FETCH_MEMBERS_SUCCESS = 'FETCH_MEMBERS_SUCCESS';
export const FETCH_MEMBERS_FAIL = 'FETCH_MEMBERS_FAIL';
export const APPLY_LICENSE_SUCCESS = 'APPLY_LICENSE_SUCCESS';


/* Add Member */
export function addMember(member) {
    return function (dispatch) {
        const request = axios.post(process.env.REACT_APP_ENDPOINT_URL + '/v1/members', member);
          
        return request.then(response => {
            dispatch(addMemberSuccess(response))
        },
        err => console.log(err)
        );
    }
}
function addMemberSuccess(json) {
    return  { 
        type: ADD_MEMBER_SUCCESS, 
        member: json.data.member
    }
}


/* Update Member */
export function updateMember(member) {
    return function (dispatch) {
        
        const request = axios.patch(process.env.REACT_APP_ENDPOINT_URL + '/v1/members', member);
        console.log(request)
          
        return request.then(response => {
            
            dispatch(updateMemberSuccess(response))
        },
        err => console.log("Updating member failed. Error: ", err)
        );
    }
}

function updateMemberSuccess(json) {
    return  { 
        type: UPDATE_MEMBER_SUCCESS, 
        member: json.data.member,
        receivedAt: Date.now()
    }
}


/* Delete Member */
export function deleteMember(id) {
    return function (dispatch) {
        
        const request = axios.delete(process.env.REACT_APP_ENDPOINT_URL + '/v1/members/', {params: {_id: id}});
          
        return request.then(response => {
            dispatch(deleteMemberSuccess(response.data.member_id))
        },
        err => console.log(err)
        );
    }
}
function deleteMemberSuccess(id) {
    return  { 
        type: DELETE_MEMBER_SUCCESS, 
        id: id,
        receivedAt: Date.now()
    }
}


/* Fetch Member */
export function fetchMembers() {
    return function (dispatch) {
     
        dispatch(requestMembers())
        
        const request = axios.get(process.env.REACT_APP_ENDPOINT_URL + '/v1/members');
          
        return request.then(response => {
            dispatch(fetchMembersSuccess(response))
        },
        err => dispatch(fetchMembersFail(err))
        );
    }
}
function requestMembers() {
    return { 
        type: REQUEST_MEMBERS
    }
}
function fetchMembersSuccess(json) {
    return {
        type: FETCH_MEMBERS_SUCCESS,
        members: json.data.members.map(member => member),
        receivedAt: Date.now()
    }
}

function fetchMembersFail(json) {
    return {
        type: FETCH_MEMBERS_FAIL,
    }
}