import React, {Fragment, useState, useContext } from "react"
import StateContext from '../../MemberContext'
import { fetchClinics } from '../../syncComponents/helpers'
import LoadingBar from '../../uitools/LoadingBar'

const AddBluePrintApptType = ({appointments, type}) => {
    const {setMemberInfo} = useContext(StateContext);

    const onChange = (idx) => {
        const {id, duration, name} = appointments[idx]
        setMemberInfo( prev => ({
            ...prev, 
            ["appointment_type_id_" + type + "_patient"] : id,
            ["appointment_type_name_" + type + "_patient"] : name,
            ["appointment_type_length_" + type + "_patient"] : duration
        }))
    }

    return(
        <div className="c-form__form-group">
            <label>{type} Appointments</label>
            <select onChange={(e) => onChange(e.target.value)}>
                {appointments.map( ({name}, idx) =>
                    <option value={idx}>{name}</option>
                )}
            </select>
        </div>
    )
}

const AddBlueprintMember = ({customers, handleSubmit, handleInputChange}) => {
    const [clinicData, setClinics] = useState(null)
    const {memberInfo, setMemberInfo} = useContext(StateContext);
    const [err, setErr] = useState(null)
    const [fetching, setFetching ] = useState(false)

    const SearchLocation = async() => {
        const {api_endpoint, apiKey} = memberInfo
        if(!api_endpoint) {
            setErr("Missing api endpoint")
        } else if(!apiKey) {
            setErr("Missing api key")
        } else {
            setErr(null)
            setFetching(true) 
            try {
                const {data} = await fetchClinics({api_endpoint, apiKey})
                setClinics(data)
            } catch (err) {
                console.error(err)
                setErr("There was an error fetching the response. Please check the console for error.")
            }
            setFetching(false)
        }
    }

    return(
        <form onSubmit={handleSubmit} className="c-form">
            <LoadingBar isLoading={fetching} />
            {err && <span className="c-form__error">{err}</span>}
            <div className="c-form__form-group">
                <label>Blueprint Endpoint</label>
                <input 
                    type="text" 
                    name="api_endpoint" 
                    className="form-control" 
                    onChange={handleInputChange} 
                    value={memberInfo.api_endpoint} 
                />
            </div>
            <div className="c-form__form-group">
                <label>Api key</label>
                <input 
                    type="text" 
                    name="apiKey" 
                    className="form-control" 
                    onChange={handleInputChange} 
                    value={memberInfo.apiKey} 
                />
            </div>
            {!clinicData && 
                <div className="c-form__controls">
                    <button onClick={SearchLocation} type="button" className="c-btn">Search For Location</button>
                </div>
            }
            {clinicData && <AddBluePrintClinic clinics={clinicData.clinics} setMemberInfo={setMemberInfo} /> }
            {memberInfo.clinic_name !== '' && <AddBlueprintMemberForm customers={customers} handleSubmit={handleSubmit} handleInputChange={handleInputChange} {...clinicData} />}
        </form>
    )
}

const AddBluePrintClinic = ({clinics, setMemberInfo}) => {

    const handleClinicChange = idx => {
        const {companyName, id, timeZone} = clinics[idx]
        setMemberInfo(prev => ({
            ...prev, 
            clinic_name: companyName, 
            clinic_id: companyName + '_' + id,
            clinic_timezone: timeZone
        }))
        return 1
    }
    return (
        <div className="c-form__form-group">
            <label> Select a clinic </label>
            <select 
                name="clinic" 
                className="form-control"
                onChange={(e) => handleClinicChange(e.target.value)}
                defaultValue="0"
            >
                <option value="0">Please Select a Clinic</option>
                {clinics.map( ({city, companyName, id}, idx) => 
                    <option key={id} value={idx}>{city+ ' ' + companyName}</option>
                )}
            </select>
        </div>
    )
}

const AddBlueprintMemberForm = ({customers, handleSubmit, handleInputChange, appointmentTypes}) => {
    const {memberInfo} = useContext(StateContext);
    return (
        <Fragment>
            <div className="c-form__form-group">
                <label>Clinic Name</label>
                <input 
                    type="text" 
                    name="clinic_name" 
                    className="form-control" 
                    onChange={handleInputChange} 
                    value={memberInfo.clinic_name} 
                />
            </div>
            <div className="c-form__form-group">
                <label>Account Number</label>
                <input 
                    type="text" 
                    name="account_number" 
                    className="form-control" 
                    onChange={handleInputChange} 
                    value={memberInfo.account_number} 
                />
            </div>
            <div className="c-form__form-group">
                <label>Customer</label>
                <select 
                    name="customer_id" 
                    className="form-control"
                    onChange={handleInputChange}
                    defaultValue="0"
                >
                    <option value="0" disabled>Choose Customer</option>
                    { customers && customers.map( ({_id,  name}) => {
                        return (
                            <option key={_id} value={_id}> {name} </option>
                        )
                    })}
                </select>
            </div>

            <div className="c-form__form-group">
                <label>Email</label>
                <input 
                    type="text" 
                    name="email" 
                    className="form-control" 
                    onChange={handleInputChange} 
                    value={memberInfo.email} 
                />
            </div>
            <div className="c-form__form-group">
                <label>Notification Emails</label>
                <input 
                    type="text" 
                    name="notification_emails" 
                    className="form-control" 
                    onChange={handleInputChange} 
                    value={memberInfo.notification_emails} 
                />
            </div>
            <div className="c-form__form-group">
                <label> address street 1 </label>
                <input 
                    type="text" 
                    name="street1" 
                    className="form-control" 
                    onChange={handleInputChange} 
                    value={memberInfo.street1} 
                />
            </div>
            <div className="c-form__form-group">
                <label> address street 2 </label>
                <input 
                    type="text" 
                    name="street2" 
                    className="form-control" 
                    onChange={handleInputChange} 
                    value={memberInfo.street2} 
                />
            </div>
            <div className="c-form__form-group">
                <label> City </label>
                <input 
                    type="text" 
                    name="city" 
                    className="form-control" 
                    onChange={handleInputChange} 
                    value={memberInfo.city} 
                />
            </div>
            <div className="c-form__form-group">
                <label> State </label>
                <input 
                    type="text" 
                    name="state" 
                    className="form-control" 
                    onChange={handleInputChange} 
                    value={memberInfo.state} 
                />
            </div>
            <div className="c-form__form-group">
                <label> Zip </label>
                <input 
                    type="text" 
                    name="zip" 
                    className="form-control" 
                    onChange={handleInputChange} 
                    value={memberInfo.zip} 
                />
            </div>
            <div className="c-form__form-group">
                <label>Phone</label>
                <input 
                    type="text" 
                    name="phone" 
                    className="form-control" 
                    onChange={handleInputChange} 
                    value={memberInfo.phone} 
                />

            </div>
            <AddBluePrintApptType appointments={appointmentTypes} type="new"/>
            <AddBluePrintApptType appointments={appointmentTypes} type="returning"/>
            <div className="c-form__controls"><button type="submit" className="c-btn">Create</button></div>

        </Fragment>
    )
}

export default AddBlueprintMember