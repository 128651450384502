import axios from 'axios';

/*
* Auth Actions
*/


export const RECEIVE_TOKEN = 'RECEIVE_TOKEN';
export const REMOVE_TOKEN = 'REMOVE_TOKEN';


function receiveToken(json) {
    
    axios.defaults.headers.common['x-access-token'] = json.data.token;
    
    return {
        type: RECEIVE_TOKEN,
        auth: json.data.auth,
        token: json.data.token,
        receivedAt: Date.now()
    }
}


export function removeToken(json) {
    return {
        type: REMOVE_TOKEN,
    }
}


export function authenticate(credentials) {

    return function (dispatch) {
     
        
        const request = axios.post(process.env.REACT_APP_ENDPOINT_URL + '/v1/auth/login',{ logemail: credentials.email, logpassword: credentials.password })
          
        return request.then(response => {
            console.log(response); 
            dispatch(receiveToken(response))
        },
        err => console.log(err)
        );



    }
}