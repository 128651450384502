/************************************************
 * @description A small helpers library to deal with
 * reusable small components
 * 
 * 
 * @TODO
 * ↳ 
************************************************/
import axios from 'axios'
import StateContext from '../MemberContext'
import LoadingBar   from '../uitools/LoadingBar'
import React, { Fragment, useState, useEffect, useContext } from 'react'
import useFetchLoginToken from '../../hooks/useFetchLoginToken'

/****************************************
 * @description Fetch the locations from blueprints
 * memberCredientails contains the fields 
 * apiKey
 * api_endpoint
****************************************/
const fetchClinics = async memberCredentials => {
  let params = {
    params: { ...memberCredentials }
  }
  return await axios.get(process.env.REACT_APP_ENDPOINT_URL + '/blueprint/clinics', params)
}

const handleLogin = (memberInfo) => {
    let params = {
        params: {
            member: memberInfo.username,
            pass: memberInfo.password,
            type: memberInfo.nextslot_endpoint,
            api_endpoint: memberInfo.api_endpoint
        }
    }
    let token = axios.get(process.env.REACT_APP_ENDPOINT_URL + '/v2/login', params) 
    return Promise.all([token])
}

/************************************************
 * @TODO
 * ↳ add fetcher for sycle classic
************************************************/
const AppointmentTypesSycle = ({appointmentTypes}) => {
  const { memberInfo, setMemberInfo } = useContext(StateContext);
  const { token } = useFetchLoginToken({memberInfo})
  const [ error,  setError ] = useState(null)
  const [options, setOptions ] = useState(null)


  const setAppointmentTypes = appointmentTypes => {
    setOptions(appointmentTypes.map( ({appt_type_id, length, name}) => {
      let optionValue = JSON.stringify({name, id: appt_type_id, length: length})
      return <option key={name+length} value={optionValue}>{name + ' (' + length + ')'}</option> 
    }))
  }

  useEffect( () => {
    if(appointmentTypes) { //appointmenttypes already obtained
      setAppointmentTypes(appointmentTypes)
    } 
  }, [])

  /**********************************************
   * Appointment types not given. need to wait for
   * a login token and fetch the appt types
   **********************************************/
  useEffect( () => {
    if(!appointmentTypes && token.token) {
      let params = {
        params: {
          token: token.token,
          clinic_id: [memberInfo.clinic_id],
          api_endpoint: memberInfo.api_endpoint
        }
      }
      axios.get(process.env.REACT_APP_ENDPOINT_URL + '/v1/clinics', params).then( response => {
        setAppointmentTypes(response.data.clinic_details[0].appointment_types)
      }).catch ( err => {
        console.log("Error fetching the clinics. Error message ", err)
        setError("Error fetching the clinics. Please check the logs")
      })
    }
  }, [token, appointmentTypes])

  return(
    <Fragment>
      {error && <span className="c-form__error">{error}</span>}
        <AppointmentTypesField options={options} />
    </Fragment>
  )
}


const AppointmentTypeSyclePro = () => {
    const [ error, setError ] = useState()
    const { memberInfo} = useContext(StateContext); 
    const [ appointmentTypes, setAppointmentTypes] = useState(null)

    useEffect( () => {
        handleLogin(memberInfo).then( token_response => {
            let token = token_response[0].data
            let params = {
                params: {
                    token_type: token.token_type,
                    token_key: token.token_key,
                    parent_company_id: memberInfo.parent_company_id,
                    clinic_id: memberInfo.clinic_id
                }
            }
            axios.get(process.env.REACT_APP_ENDPOINT_URL + '/v2/auth_appointment_types', params).then( (response) => {
                setAppointmentTypes(response.data.appointment_types.map( type => {
                    let optionValue = JSON.stringify({
                        name: type.appointmentName, 
                        id: type.id,
                        length: type.appointmentLength
                    })
                    return <option key={type.id} value={optionValue}> {type.appointmentName} </option> 
                })) }).catch(err => {
                setError(" There was an error in fetching the appointment types: ", err)
                setAppointmentTypes([])
            })
        }).catch ( err => {
            setError("There was an error logging in: " + err)
            setAppointmentTypes([])
        })
    }, [memberInfo.clinic_id])

    return(
        <Fragment>
            {error && <span className="c-form__error">{error}</span>}
            <AppointmentTypesField options={appointmentTypes} />
        </Fragment>
    )
}

const AppointmentTypeBlueprint = () => {
    const [ error, setError ] = useState()
    const { memberInfo } = useContext(StateContext); 
    const [ appointmentTypes, setAppointmentTypes] = useState(null)

    useEffect( () => {

        let params = {
          apiKey: memberInfo.apiKey,
          api_endpoint: memberInfo.api_endpoint
        }
        axios.get(process.env.REACT_APP_ENDPOINT_URL + '/blueprint/clinics', {params: params}).then( (response) => {

                setAppointmentTypes(response.data.appointmentTypes.map( type => {
                    let optionValue = JSON.stringify({
                        name: type.name, 
                        id: type.id,
                        length: type.duration
                    })
                    if(type.name !== null)
                      return <option key={type.id} value={optionValue}> {type.name} </option> 
                })) }).catch(err => {
                setError(" There was an error in fetching the appointment types: ", err)
                setAppointmentTypes([])
            }).catch ( err => {
            setError("Could not get Clinic Info: " + err)
            setAppointmentTypes([])
        })
    }, [memberInfo.clinic_id])

    return(
        <Fragment>
            {error && <span className="c-form__error">{error}</span>}
            <AppointmentTypesField options={appointmentTypes} />
        </Fragment>
    )
}

const AppointmentTypesField = ({options}) => {
  const { memberInfo, setMemberInfo } = useContext(StateContext);
  let newDefault = memberInfo.appointment_type_id_new_patient
  if (newDefault && memberInfo.appointment_type_id_new_patient !== "custom") {
    let name = memberInfo.appointment_type_name_new_patient
    let id = Number(memberInfo.appointment_type_id_new_patient)
    id = id ? id : memberInfo.appointment_type_id_new_patient
    let length = Number(memberInfo.appointment_type_length_new_patient)
    newDefault = JSON.stringify({name, id, length})
  }
  newDefault = !newDefault ? "0" : newDefault

  let returningDefault = memberInfo.appointment_type_id_returning_patient
  if (returningDefault && memberInfo.appointment_type_id_returning_patient !== "custom") {
    let name = memberInfo.appointment_type_name_returning_patient
    let id = Number(memberInfo.appointment_type_id_returning_patient)
    id = id ? id : memberInfo.appointment_type_id_returning_patient
    let length = Number(memberInfo.appointment_type_length_returning_patient)
    returningDefault = JSON.stringify({name, id, length})
  }
  returningDefault =!returningDefault ? "0" : returningDefault

  const handleAppointmentChange = (e) => {
    e.preventDefault();
    let {name, value} = e.target;
    if(value !== "custom") {
      value = JSON.parse(value)
      setMemberInfo({...memberInfo, ['appointment_type_id_'+name]: value.id.toString(), ['appointment_type_name_'+name]: value.name, ['appointment_type_length_' + name]: value.length })
    } else {
      setMemberInfo({...memberInfo, ['appointment_type_id_'+name]: "custom", ['appointment_type_name_'+name]: "", ['appointment_type_length' + name]: "" })
    }
  }

  const handleNameChange = (value, type) => {
    setMemberInfo({
      ...memberInfo, 
      ['appointment_type_id_' + type + '_patient']: "custom",
      ['appointment_type_name_' + type + '_patient']: value,
    })
  }

  const handleLengthChange = (value, type) => {
    setMemberInfo({
      ...memberInfo, 
      ['appointment_type_length_' + type + '_patient']: value,
    })

  }
    return(
        <div className="c-form__form-group">
            <LoadingBar isLoading={!options} />
            { !options && <span style={{fontWeight: 700}}> Fetching Appointment types from live site ...</span> }
            { options && 
                <Fragment>
                    <div className="c-form__form-group">
                      <label> Select an appointment type for new patients </label>
                      <select 
                          name         = "new_patient" 
                          onChange     = {handleAppointmentChange}
                          className    = "form-control"
                          value        = {newDefault}
                        >
                          <option value="0"> Select an appointment type </option>
                          {memberInfo.nextslot_endpoint == "/v1/" && <option value="custom">Custom Option</option> }
                          { options }
                      </select>

                      <label> Select an appointment type for returning patients </label>
                      <select 
                          name         = "returning_patient" 
                          onChange     = {handleAppointmentChange}
                          className    = "form-control"
                          value = {returningDefault}
                      >
                          <option value="0"> Select an appointment type </option>
                          {memberInfo.nextslot_endpoint == "/v1/" && <option value="custom">Custom Option</option> }
                          { options }
                        </select>
                    </div>
                    {memberInfo.nextslot_endpoint == "/v1/" &&
                    <Fragment>
                      <div className="c-form__form-group">
                        <label>Custom New Patient Appointment Type Name</label>
                        <input 
                          value={memberInfo.appointment_type_id_new_patient === "custom" ? memberInfo.appointment_type_name_new_patient : ""} 
                          className="form-control"
                          onChange={(e) => handleNameChange(e.target.value, "new")}
                        />
                        <label>Custom New Patient Appointment Type Length</label>
                        <input 
                          value={memberInfo.appointment_type_id_new_patient === "custom" ? memberInfo.appointment_type_length_new_patient : ""} 
                          className="form-control"
                          onChange={(e) => handleLengthChange(e.target.value, "new")}
                        />
                      </div>
                      <div className="c-form__form-group">
                        <label>Custom Returning Patient Appointment Type Name</label>
                        <input 
                          value={memberInfo.appointment_type_id_returning_patient === "custom" ? memberInfo.appointment_type_name_returning_patient : ""} 
                          className="form-control"
                          onChange={(e) => handleNameChange(e.target.value, "returning")}
                        />
                        <label>Custom Returning Patient Appointment Type Length</label>
                        <input 
                          value={memberInfo.appointment_type_id_returning_patient === "custom" ? memberInfo.appointment_type_length_returning_patient : ""} 
                          className="form-control"
                          onChange={(e) => handleLengthChange(e.target.value, "returning")}
                        />
                      </div>
                    </Fragment>
                  }
                </Fragment>
            }
        </div>
    )
}

/************************************************
 * @description Display a select field that contains 
 * the options avaiable to connext nextslot to x api
 * 
 * 
 * @TODO
 * ↳ Remove weird option depedency to not use that value as the api_endpoint
 *      ↳ api_endpoint needs to be manually typed in!
************************************************/
const SelectEndpointsField = () => {
    const { memberInfo, setMemberInfo } = useContext(StateContext); 

    const handleEndpointChange = (e) => {
        e.preventDefault();
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        const nextslot_endpoint = target.options[target.selectedIndex].dataset.nextslot_endpoint
        setMemberInfo({...memberInfo, [name]: value, nextslot_endpoint: nextslot_endpoint})
    }

    const handleInputChange = (e) => {
        e.preventDefault();
        const target = e.target
        const value = target.value;
        const name = target.name;
        setMemberInfo({...memberInfo, [name]: value, nextslot_endpoint: '/v1/'})
    }
    
    return (
        <Fragment> 
            <div className="c-form__form-group">
                <label>Scheduler Type</label>
                <select
                    name="nextslot_endpoint"
                    className="form-control"
                    onChange={handleEndpointChange}
                    value={memberInfo.nextslot_endpoint}
                >
                    <option value="/v1/" data-nextslot_endpoint="/v1/"> Sycle Classic </option>
                    <option value="/v2/" data-nextslot_endpoint="/v2/"> Sycle Pro </option>
                    <option value="/blueprint/" data-nextslot_endpoint="/blueprint/">BluePrint Scheduler</option>
                </select>
            </div>
            <div className="c-form__form-group">
                <label>Endpoint url </label>
                <input 
                    type="text" 
                    name="api_endpoint" 
                    className="form-control" 
                    onChange={handleInputChange} 
                    value={memberInfo.api_endpoint} 
                />
            </div>
        </Fragment>
    )
}

const FilterMembersByCustomer = ({customers, members, setMembers}) => {

  const onChangeCustomer = (e) => {
    let id = e.target.value
    setMembers(members.filter( ({customer_id}) => customer_id === id))
  }

  return (
    <SelectCustomer customers={customers} onChangeCustomer={onChangeCustomer} />
  )
}

const SelectCustomer = ({customers, onChangeCustomer, defaultValue}) => {
  return (
    <div className="c-form__form-group">
      <label>Select a customer</label>
      <select 
        name="customer_id" 
        className="form-control"
        onChange={onChangeCustomer}
        defaultValue={defaultValue ? defaultValue : "0" }
      >
        <option value="0" disabled>Choose Customer</option>
          { customers && customers.map( ({_id,  name}) => 
            <option key={_id} value={_id}> {name} </option>
          )}
      </select>
    </div>
  )
}

export { 
  handleLogin, 
  fetchClinics,
  FilterMembersByCustomer,
  AppointmentTypesSycle,
  AppointmentTypeSyclePro, 
  AppointmentTypeBlueprint,
  SelectEndpointsField,
  SelectCustomer
} 
