import React, { useState } from 'react';
import { Route } from 'react-router-dom'
import PrivateRoute from '../containers/PrivateRoute';
import Authenticate from '../containers/Authenticate'; 
import Admin from './Admin';
import './App.css';
import Header from './Header'
import { Container } from '@material-ui/core'

const App = () => {
	const [loggedIn, setLoggedIn] = useState(false)
	return (
		<div id="app">
			<Header isLoggedIn={loggedIn} />
			<Container maxWidth={"xl"}>
				<Route path="/login" component={(props) => <Authenticate {...props} />} />
				<PrivateRoute path="/admin/" component={Admin} setLoggedIn={setLoggedIn} />
			</Container>
		</div>
	);
}

export default App;
