import axios from 'axios';

/*
* License Actions
*/

export const ADD_LICENSES_SUCCESS = 'ADD_LICENSES_SUCCESS';
export const FETCH_LICENSES_SUCCESS = 'FETCH_LICENSES_SUCCESS';
export const FETCH_LICENSES_FAIL = 'FETCH_LICENSES_FAIL';
export const DELETE_LICENSE_SUCCESS = 'DELETE_LICENSE';
export const UPDATE_LICENSE_SUCCESS = 'UPDATE_LICENSE_SUCCESS';


/* Add Licenses */
export function addLicenses(customer) {
    return function (dispatch) {
        
        const request = axios.post(process.env.REACT_APP_ENDPOINT_URL + '/v1/licenses', customer);
          
        return request.then(response => {
            dispatch(addLicensesSuccess(response))
        },
        err => console.log(err)
        );
    }
}
function addLicensesSuccess(json) {
    return  { 
        type: ADD_LICENSES_SUCCESS,
        licenses: json.data.licenses,
        receivedAt: Date.now()
    }
}


/* Delete License */
export function deleteLicense(id, customer_id) {
    return function (dispatch) {
        
        const request = axios.delete(process.env.REACT_APP_ENDPOINT_URL + '/v1/licenses/'+id);
          
        return request.then(response => {
            dispatch(deleteLicenseSuccess(response))
        },
        err => console.log(err)
        );
    }
}
function deleteLicenseSuccess(json) {
    return  { 
        type: DELETE_LICENSE_SUCCESS, 
        license_id: json.data.license_id,
        receivedAt: Date.now()
    }
}



/* Update License */
export function updateLicense(license) {
    return function (dispatch) {
        
        const request = axios.patch(process.env.REACT_APP_ENDPOINT_URL + '/v1/licenses', license);
          
        return request.then(response => {
            
            dispatch(updateLicenseSuccess(response))
        },
        err => console.log(err)
        );
    }
}

function updateLicenseSuccess(json) {
    return  { 
        type: UPDATE_LICENSE_SUCCESS, 
        license: json.data.license,
        receivedAt: Date.now()
    }
}






/* Fetch Member */
export function fetchLicenses() {
    return function (dispatch) {
        
        const request = axios.get(process.env.REACT_APP_ENDPOINT_URL + '/v1/licenses');
          
        return request.then(response => {
            dispatch(fetchLicensesSuccess(response))
        },
        err => dispatch(fetchLicensesFail(err))
        );
    }
}
export function fetchCustomerLicenses(id) {
    return function (dispatch) {
        
        const request = axios.get(process.env.REACT_APP_ENDPOINT_URL + '/v1/licenses', {params: {customer_id: id}});
          
        return request.then(response => {
            dispatch(fetchLicensesSuccess(response))
        },
        err => dispatch(fetchLicensesFail(err))
        );
    }
}

function fetchLicensesSuccess(json) {
    return {
        type: FETCH_LICENSES_SUCCESS,
        licenses: json.data.licenses.map(license => license),
        receivedAt: Date.now()
    }
}

function fetchLicensesFail(json) {
    return {
        type: FETCH_LICENSES_FAIL,
    }
}
