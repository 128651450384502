import React from "react"
import AppBar from '@material-ui/core/AppBar';
import { Button, Toolbar,  InputBase, Typography } from '@material-ui/core'
import { Grid } from '@material-ui/core'
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux'
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { removeToken } from '../actions/auth'

const AdminHeader = () => {
    const dispatch = useDispatch()
    const onLogout = () => dispatch(removeToken())
    return(
        <AppBar position="static"  classes={{root:"c-header__admin"}}>
            <Toolbar>
                <Grid container justify="space-between">
                    <Grid item>
                        <Grid container spacing={3}>
                            {/*<Grid item> <Avatar alt="Remy Sharp" src="/nextslot-logo.png" variant="square" style={{marginLeft: "10px", width: "50px", height:"50px"}}/> </Grid>*/}
                            <Grid item> <Typography variant="h6" noWrap> NextSlot Management</Typography> </Grid>
                        </Grid>
                    </Grid>
                    <Grid item >
                        <Grid container spacing={1}>
                            <Grid item> 
                                <Button color="inherit">
                                    <Link className="wcn-header__link" to='/admin/members'>Members</Link>
                                </Button> 
                            </Grid>
                            <Grid item> 
                                <Button color="inherit">
                                    <Link className="wcn-header__link" to='/admin/customers/'>Customers</Link>
                                </Button> 
                            </Grid>
                            <Grid item> <Button onClick={onLogout} startIcon={<ExitToAppIcon />} color="inherit">Logout</Button> </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    )
}

const PublicHeader = () => {
    return(
        <header className="c-header">
            <div className="o-wrapper"> <h1>NextSlot API</h1> </div>
        </header>
    )
}
const Header = ({isLoggedIn}) => {
    if(isLoggedIn)
        return <AdminHeader />
    else
        return  <PublicHeader /> 
}

export default Header