import React, { useEffect, useState } from 'react';
import ModalWrapper from './modal/ModalWrapper';
import AddMember from './modal/AddMember/AddMember';
import ApplyMultipleLicenses from './modal/ApplyMultipleLicenses'
import ApplyAppointmentTypes from './modal/ApplyAppointmentTypes'
import MembersTable from './MembersTable'
import SearchIcon from '@material-ui/icons/Search';
import { Paper, Grid, Button, Typography } from '@material-ui/core'
import MemberFilterDrawer from './MemberFilterDrawer'
import InputBase from '@material-ui/core/InputBase';

const MembersSearchBar = ({onApplySearch}) => {
  const [quickSearch, setSearch] = useState('')

  const onChangeSearchInput = value => setSearch(value)
  
  return(
    <Grid container spacing={3}  alignItems="center" >
      <Paper style={{padding: "5px"}}>
        <Grid container spacing={3}  alignItems="center"  >
          <Grid item xs={2} ><SearchIcon /></Grid>
          <Grid item xs={10}>
            <InputBase
              placeholder="Search Members"
              inputProps={{ 'aria-label': 'search members' }}
              onChange={(e) => onChangeSearchInput(e.target.value)}
              value={quickSearch}
            />
          </Grid>
        </Grid>
      </Paper>
      <Grid item> <Button onClick={() => onApplySearch(quickSearch)} variant="contained">Search</Button> </Grid>
    </Grid>
  )
}

const ManageMembers = ({members}) => {
  const [filteredSearchMembers, setFilteredSearchMembers] = useState([])
  const [filteredMembers, setFilteredMembers] = useState(filteredSearchMembers)

  useEffect( () => {
    if(members && members.length !== 0 ) {
      setFilteredSearchMembers(members)
      setFilteredMembers(members)
    }
  }, [members])

  const onApplySearch = quickSearch => {
    const membersNew = members.filter((member) => {
            member.account_number = member?.account_number ? member.account_number : '';
            member.street1 = member?.street1 ? member.street1 : '';
            member.city = member?.city ? member.city : '';
            member.state = member?.state ? member.state : '';
            member.zip = member?.zip ? member.zip : '';
            if(
                member.clinic_id.toLowerCase().includes(quickSearch.toLowerCase()) ||
                member.account_number.toLowerCase().includes(quickSearch.toLowerCase()) ||
                member.api_endpoint.toLowerCase().includes(quickSearch.toLowerCase()) ||
                member.username.toLowerCase().includes(quickSearch.toLowerCase()) ||
                member.email.toLowerCase().includes(quickSearch.toLowerCase()) ||
                member.notification_emails.toLowerCase().includes(quickSearch.toLowerCase()) || 
                member.customer.name.toLowerCase().includes(quickSearch.toLowerCase()) ||
                member.street1.toLowerCase().includes(quickSearch.toLowerCase()) ||
                member.city.toLowerCase().includes(quickSearch.toLowerCase()) ||
                member.state.toLowerCase().includes(quickSearch.toLowerCase()) ||
                member.zip.toLowerCase().includes(quickSearch.toLowerCase())
            ) {
                return true
            }
        }
    )
    setFilteredSearchMembers(membersNew)
    setFilteredMembers(membersNew)
  }

  //https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort
  const onSortColumn = (field, order) => {
    const isAsc = order === 'asc'
    const sortedMembers = members.sort((a, b) => {
      if(!a[field] && !b[field])
        return 0
      if(!b[field])
        return isAsc ? -1 : 1;
      if(!a[field])
        return isAsc ? 1 : -1;

      const fieldA = a[field].toUpperCase(); // ignore upper and lowercase
      const fieldB = b[field].toUpperCase(); // ignore upper and lowercase
      if (fieldA < fieldB) {
        return isAsc ? -1 : 1;
      } else if (fieldA > fieldB) {
        return isAsc ? 1 : -1;
      }
      return 0; //fields are equal
    });
    setFilteredSearchMembers(sortedMembers)
    setFilteredMembers(sortedMembers)
    return isAsc ? 'desc' : 'asc'
  }

  return (
    <div className="o-wrapper">
      <Grid container direction="row" justify="space-between" alignItems="center" style={{paddingTop: "20px"}}>
        <Grid item><Typography variant="h5">Members</Typography></Grid>
        <Grid item>
          <Grid container justify="center" spacing={3}>
            <Grid item> 
              <ModalWrapper
                title="Add Member"
                buttonText="Add Member"
                width={800}
                showOk={false}
                component={AddMember}
              /> 
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/************************************************** Search and Filter *****************************************/}
      <Grid container justify="space-between" alignItems="center" style={{paddingTop: "20px", paddingBottom: "20px"}}>
        <Grid item><MembersSearchBar onApplySearch={onApplySearch}/></Grid>
        <Grid item><MemberFilterDrawer members={filteredSearchMembers} setMembers={setFilteredMembers}/></Grid>
      </Grid>

      {/************************************************** Table *****************************************/}
      <MembersTable members={filteredMembers} onSort={onSortColumn}/>
    </div>
  )
}

export default ManageMembers;
