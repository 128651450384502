import React, { Component } from 'react';
import Navigation from './Navigation'; 
import PrivateRoute from '../containers/PrivateRoute';
import CustomerList from '../containers/CustomerList';
import LicenseList from '../containers/LicenseList';
import MemberList from '../containers/MemberList'; 
import './App.css';
import UpdateMembersPage from '../pages/UpdateMembersPage'
import ResponsiveSidebar from './ResponsiveSidebar'

class App extends Component {
    render() {
		return (
			<div className="wcn-admin-wrapper">
                { /****<ResponsiveSidebar />  ****/}
                {/*<Navigation /> */}
				<PrivateRoute exact path="/admin/customers/" component={CustomerList} />
				<PrivateRoute exact path="/admin/customers/:id/licenses" component={LicenseList} />
				<PrivateRoute exact path="/admin/members" component={MemberList} />
				<PrivateRoute exact path="/admin/syncMembers" component={UpdateMembersPage} />
			</div>
		);
  	}
}

export default App;
