import React, { Component } from 'react';
import { Redirect } from 'react-router-dom'

class LogInPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            credentials: {
                email: '',
                password: '',
            }
        };
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.onLogin(this.state.credentials);
    }

    handleInputChange = (e) => {
        e.preventDefault();
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState(prevState => ({
            credentials: {
                ...prevState.credentials,
                [name]: value
            }
        }))
    }
    
    render() {
        if(this.props.auth.isAuthenticated) {
            return (
                <Redirect
                    to={{
                        pathname: "/admin/members",
                        state: { from: this.props.location }
                    }}
                />
            )
        }
        return (
            <div className="o-wrapper">
                
                <h1>Login</h1>
                <form className="c-form" onSubmit={this.handleSubmit}>
                    <div className="c-form__form-group">
                        <label>Username</label>
                        <input 
                            type="text" 
                            name="email" 
                            className="form-control"
                            value={this.state.credentials.email} 
                            onChange={this.handleInputChange} 
                        />
                    </div>
                    <div className="c-form__form-group">
                        <label>Password</label>
                        <input 
                            type="password" 
                            name="password" 
                            className="form-control" 
                            value={this.state.credentials.password} 
                            onChange={this.handleInputChange}  
                        />
                    </div>
                    <div className="c-form__controls">
                        <button type="submit" className="c-btn">Login</button>
                    </div>
                </form>
            </div>
        )
    }
}

export default LogInPage;