import LoadingBar from '../../uitools/LoadingBar'
import StateContext from '../../MemberContext'
import SyncClinicAddress from '../../syncComponents/SyncAddress'
import React, { Fragment, useEffect, useState, useContext} from 'react'
import axios from 'axios'
import { AppointmentTypeSyclePro } from '../../syncComponents/helpers'
import { handleLogin } from '../../syncComponents/helpers'

const AddMemberWithToken = ({customers, token, handleSubmit, handleInputChange}) => {
    const { memberInfo, setMemberInfo } = useContext(StateContext); 
    const [parentCompanies, setParentCompanies] = useState([])
    const [loading, setLoading] = useState(false)
    const [ clinics, setClinics] = useState(null)
    const [ failedFetching, setFailedFetching] = useState(false)
    let currCustomers = customers ? customers : []
    let params = {
        params: {
            token_type: token.token_type,
            token_key: token.token_key
        }
    }

    const get_parent_companies  = async() => {
        setLoading(true)
        let parent_company = await axios.get(process.env.REACT_APP_ENDPOINT_URL + '/v2/auth_clinics', params)
        parent_company = parent_company.data.parent_clinics
        setParentCompanies(parent_company)
        setLoading(false)
    }

    const get_clinics = async() => {
        setLoading(true)
        params.params['parent_company_id'] = memberInfo.parent_company_id
        try {
            let response = await axios.get(process.env.REACT_APP_ENDPOINT_URL + '/v2/clinic_info', params)
            setClinics(response.data.data)
            setLoading(false)
        } catch ( err)  {
            console.log("Error fetching the clinics: ", err)
            setFailedFetching(true)
        }
    }

    useEffect(() => {
        get_parent_companies()
        //get_clinics()
    }, [])


    const handleClinicChange = (e) => {
        e.preventDefault();
        let target = e.target;
        let value = target.value;
        value = JSON.parse(value)
        setMemberInfo({...memberInfo, clinic_id:  value.id.toString(), clinic_name: value.name, api_endpoint: 'https://api.sycle.net' })
    }

    const onSubmitSyclePro = e => {
        e.preventDefault()
        axios.get(process.env.REACT_APP_ENDPOINT_URL + '/v2/referral_source', { params:{...params.params, parent_company_id: memberInfo.parent_company_id}}).then(res => {
            setMemberInfo({...memberInfo, referral_source_id: res.data.referralSource.id})
        })
    }

    useEffect(() => {
        if(memberInfo.referral_source_id) {
            handleSubmit()
        }
    }, [memberInfo.referral_source_id])

    const onChangeParentCompany = (id) => {
        setMemberInfo({
            ...memberInfo, 
            parent_company_id: id.toString(),
            nextslot_endpoint: '/v2/'
        })
    }

    return (
        <Fragment>
        <LoadingBar isLoading={loading} />
        { !clinics && parentCompanies.length === 0 &&
            <Fragment> 
                <span> Log In Successful! Fetching Parent Companies... </span>  
            </Fragment>
        }
        { !clinics && parentCompanies.length !== 0 &&
            <div className="c-form__form-group">
                <label> Select a Parent Company </label>
                <select 
                    name="parentCompany" 
                    className="form-control"
                    onChange={(e) => onChangeParentCompany(e.target.value)}
                    defaultValue="0"
                >
                    <option value="0" disabled>Please select a Parent Company</option>
                        { parentCompanies.map( ({parentCompanyId, companyName}) => 
                            <option key={parentCompanyId} value={parentCompanyId}>{companyName}</option>
                        )}
                </select>
                <button onClick={get_clinics} type="button" className="c-btn"> Continue </button> 
            </div>
        }
        { failedFetching && <button onClick={get_clinics}> There was an error fetching the clinics. Try fetching again </button> }

        { clinics && 
            <form onSubmit={onSubmitSyclePro} className="c-form">
                <div className="c-form__form-group">
                    <label> Select a Clinic </label>
                    <select 
                        name="clinic" 
                        className="form-control"
                        onChange={handleClinicChange}
                        defaultValue="0"
                    >
                        <option value="0" disabled>Please select a Clinic</option>
                        { clinics.map( ({name, id, address: {street1}}) => 
                            <option key={id} value={JSON.stringify({name, id})}>{name + ': ' + street1 + ' ' + id}</option>
                        )}
                    </select>
                </div>
                { memberInfo.clinic_id && <AppointmentTypeSyclePro /> }
                { memberInfo.appointment_type_id_new_patient && 
                    <Fragment>
                        <div className="c-form__form-group">
                            <label>Account Number</label>
                            <input 
                                type="text" 
                                name="account_number" 
                                className="form-control" 
                                onChange={handleInputChange} 
                                value={memberInfo.account_number} 
                            />
                        </div>
                        <div className="c-form__form-group">
                            <label>Email</label>
                            <input 
                                type="text" 
                                name="email" 
                                className="form-control" 
                                onChange={handleInputChange} 
                                value={memberInfo.email} 
                            />
                        </div>
                        <div className="c-form__form-group">
                            <label>Notification Emails</label>
                            <input 
                                type="text" 
                                name="notification_emails" 
                                className="form-control" 
                                onChange={handleInputChange} 
                                value={memberInfo.notification_emails} 
                            />
                        </div>
                        <div className="c-form__form-group">
                            <label>Customer</label>
                            <select 
                                name="customer_id" 
                                className="form-control"
                                onChange={handleInputChange}
                                defaultValue="0"
                            >
                                <option value="0" disabled>Choose Customer</option>
                                { currCustomers.map(customer => {
                                    return (
                                        <option 
                                            key={customer._id}
                                            value={customer._id}
                                        >
                                            {customer.name}
                                        </option>
                                    )
                                })}
                            </select>
                        </div>
                    </Fragment>
                 }
                 { memberInfo.email && memberInfo.notification_emails && memberInfo.customer_id &&  
                    <>
                        <SyncClinicAddress handleInputChange={handleInputChange} />
                        <button type="submit" className="c-btn"> Add member </button> 
                    </>
                }
            </form>
        }
        </Fragment>
    )
}


const AddSycleProMember = (props) => {
    const {memberInfo, setMemberInfo} = useContext(StateContext);
    const [error, setError]           = useState(null)
    const [token, setToken]           = useState(null)
    const [loggingIn, setLoggingIn]   = useState(false)

    /******** Set the nextslot endpoint to /v2/ on initla load of the component ************/
    useEffect( () => {
        setMemberInfo({...memberInfo, nextslot_endpoint: '/v2/'})
    }, [])

    const handleSycleProLogin = (e) => {
        e.preventDefault();
        setLoggingIn(true)
        handleLogin(memberInfo).then ( login_response => {
            let res = login_response[0]
            setToken(res)
            setError(null)
        }).catch( (err) => {
            setLoggingIn(false)
            setError("Failed logging in please try again")
        })
    }

    return (
        <Fragment>
        { loggingIn === false && 
            <form onSubmit={handleSycleProLogin} className="c-form">
                { error && <span className="c-form__error"> {error} </span>}
                <div className="c-form__form-group">
                    <label> Sycle Username</label>
                    <input 
                        type="text" 
                        name="username" 
                        className="form-control" 
                        onChange={props.handleInputChange} 
                        value={memberInfo.username} 
                    />
                </div>
                <div className="c-form__form-group">
                    <label> Sycle Password </label>
                    <input 
                        type="text" 
                        name="password" 
                        className="form-control" 
                        onChange={props.handleInputChange} 
                        value={memberInfo.password} 
                    />
                </div>
                <div className="c-form__controls"> <button type="submit" className="c-btn"> Login </button> </div>
            </form>
        }
        { loggingIn && !token && 
            <Fragment>
                <span> Logging into sycle </span> 
                <LoadingBar isLoading={true} />
            </Fragment>
        }
        { token && <AddMemberWithToken token={token.data} {...props} />}
        </Fragment>
    )
}

export default AddSycleProMember