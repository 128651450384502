import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger'
import { loadState, saveState } from './localStorage'
import rootReducer from '../reducers';
import { removeToken } from '../actions/auth'
import axios from 'axios'

const loggerMiddleware = createLogger()

const persistedState = loadState();

const store = createStore(
    rootReducer,
    persistedState,
    compose(
        applyMiddleware(
            thunkMiddleware, // lets us dispatch() functions
            loggerMiddleware // neat middleware that logs actions
        ),
        window.devToolsExtension ? window.devToolsExtension() : f => f
    )
)

store.subscribe(() => {
    saveState({
        auth: store.getState().auth
    });
});

const tokenDate = store.getState().auth.date;
if(tokenDate < (Date.now() - 604800) && tokenDate) {
    store.dispatch(removeToken());
}

const token = store.getState().auth.token;
if (token) {
    axios.defaults.headers.common['x-access-token'] = token;
} else {
    axios.defaults.headers.common['x-access-token'] = null;
}





export default store;