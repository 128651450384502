import { RECEIVE_TOKEN, REMOVE_TOKEN } from '../actions/auth';

const auth = (
    state = {
        isAuthenticated: false,
        token: ''
    }, 
    action
) => {
    switch (action.type) {
        case RECEIVE_TOKEN:
            return {
                isAuthenticated: action.auth,
                token: action.token,
                date: action.receivedAt
            }
        case REMOVE_TOKEN:
            return {
                isAuthenticated: false,
                token: '',
                date: null
            }
      
      
        default:
            return state
    }
}
export default auth