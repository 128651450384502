import React, { useState } from 'react'
import { useSelector } from 'react-redux'
/**************************************************
 * Select an avaiable license from a customer. 
 * If no licenses avaiable return an empty string 
**************************************************/
const selectLicense = (licenses, member, customer_id='') => {
    let openLicenseID = null
    if (member.customer ){
        customer_id = member['customer_id'] === undefined ? member.customer._id : member['customer_id']
    }

    for(let license of licenses) {
        let matching_customer = license.customer_id === customer_id
        if ( matching_customer && !license.active && !license.expired) {
            openLicenseID = license._id
            break   
        }
    }
    return openLicenseID
}



const ApplyCustomerAndLicense = ({member, onUpdateMember, onUpdateLicense, onCloseModal}) =>  {
    const licenses = useSelector(state => state.licenses.items)
    const customers = useSelector(state => state.customers.items)
    const [memberInfo, setMemberInfo] = useState({...member})

    const handleInputChange = (e) => {
        e.preventDefault();
        setMemberInfo({...memberInfo, customer_id: e.target.value, license_id: selectLicense(licenses, memberInfo, e.target.value)})
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        onUpdateMember(memberInfo)
        if (memberInfo.license_id ) { 
            onUpdateLicense({
                _id: memberInfo.license_id,
                active: true
            });
        }
        onCloseModal();
    }

    const handleClinicName = (e) => {
        e.preventDefault();
        setMemberInfo({...memberInfo, clinic_name: e.target.value})
    }

    return (
        <div>
            <span> This member does not belong to a customer. Please assign a customer to the member</span>
            <form onSubmit={handleSubmit} className="c-form">
                <div className="c-form__form-group">
                    <label> Add a clinic name </label>
                    <input type="text" 
                        name="clinic_name" 
                        className="form-control" 
                        onChange={handleClinicName} 
                        value={memberInfo.clinic_name || ''} 
                    />
                    <label>Customer</label>
                    <select 
                        name="customer_id" 
                        className="form-control"
                        onChange={handleInputChange}
                        defaultValue="0"
                    >
                        <option value="0" disabled>Choose Customer</option>
                            { customers && customers.length !== 0 && customers.map(customer => {
                                return (
                                    <option key={customer._id} value={customer._id}>
                                        {customer.name}
                                    </option>
                                )})
                            }
                    </select>
                </div>
                { !memberInfo['license_id']  && memberInfo.customer_id && <h2>This customer doesn't have any available licenses!</h2> }
                { (memberInfo['license_id'] || memberInfo.customer_id)  && 
                    <div> 
                        { memberInfo['license_id']  && <h3>Apply License #: { memberInfo.license_id}</h3>  }
                        <div className="c-form__controls">
                            <button type="submit" className="c-btn"> Add Customer and License </button>
                        </div>
                    </div>
                }
            </form>
        </div>
        )
}

export default ApplyCustomerAndLicense

export { selectLicense }