import React from 'react';

class UpdateCustomer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            customerInfo: {
                _id: this.props.customer._id,
                name: this.props.customer.name,
            }
        };
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.onUpdateCustomer(this.state.customerInfo);
        this.props.onCloseModal();
    }

    handleDelete = (e) => {
        e.preventDefault();
        var r = window.confirm("Are you sure you want to delete this customer?");
        if (r === true) {
            this.props.onDeleteCustomer(this.state.customerInfo._id);
            this.props.onCloseModal();
        } else {
            this.props.onCloseModal();
        }
    }

    handleInputChange = (e) => {
        e.preventDefault();
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState(prevState => ({
            customerInfo: {
                ...prevState.customerInfo,
                [name]: value
            }
        }))
    }

    render(props) {
        return (
            <form onSubmit={this.handleSubmit} className="c-form">
                <div className="c-form__form-group">
                    <label>Name</label>
                    <input 
                        type="text" 
                        name="name" 
                        className="form-control" 
                        onChange={this.handleInputChange} 
                        value={this.state.customerInfo.name} 
                    />
                </div>
                <div className="c-form__controls o-flex">
                    <button type="submit" className="c-btn">Update</button>
                    <button className="c-btn c-btn--warn" onClick={this.handleDelete}>Delete</button>
                </div>
            </form>

        );
    }
}

export default UpdateCustomer;