import React, { useEffect, createContext } from 'react';
import { addMember, updateMember, deleteMember, fetchMembers } from '../actions/member'
import { fetchCustomers } from '../actions/customer'
import { fetchLicenses, updateLicense } from '../actions/license'
import { useSelector, useDispatch } from 'react-redux'
import ManageMembers from '../components/ManageMembers';

const MemberActionContext = createContext({})

const MemberList = (props) =>{
    const members = useSelector(state => {
        let customers = {}
        state.customers.items.map( (customer, idx) => {
            customers[customer._id] = idx
        })
        let licenses = {}
        state.licenses.items.map( (license, idx) => {
            licenses[license._id] = idx
        })
        return state.members.items.map( member => ({
            ...member,
            customer: state.customers.items[customers[member.customer_id]],
            license: state.licenses.items[licenses[member.license_id]]
        }))
    })
    /***************** DISPATCH FUNCTIONS ******************/
    const dispatch = useDispatch()
    const onAddMember =  member => dispatch(addMember(member))
    const onUpdateMember = member => dispatch(updateMember(member))
    const onDeleteMember = id => dispatch(deleteMember(id))
    const onFetchMembers = () => dispatch(fetchMembers())
    const onFetchCustomers = () => dispatch(fetchCustomers())
    const onFetchLicenses = () => dispatch(fetchLicenses())
    const onUpdateLicense = license => dispatch(updateLicense(license))


    useEffect( () => {
        onFetchCustomers();
        onFetchMembers();
        onFetchLicenses();
    }, [])

    const providerValues = {
        onAddMember,
        onUpdateMember,
        onDeleteMember,
        onFetchMembers,
        onFetchCustomers,
        onFetchLicenses,
        onUpdateLicense
    }
    return(
        <MemberActionContext.Provider value={providerValues}>
            <ManageMembers members={members}/>
        </MemberActionContext.Provider>
    )
}

export default MemberList

export { MemberActionContext }