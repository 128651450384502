import React, { Fragment, useContext, useState } from 'react'
import LoadingBar from '../uitools/LoadingBar'
import axios from 'axios'
import StateContext from '../MemberContext'
import { handleLogin } from './helpers'
import { fetchClinicInfo } from '../../hooks/useClinicData'


const SyncClinicAddress = ({handleInputChange}) => {
    const { memberInfo, setMemberInfo} = useContext(StateContext)
    const [ err, setErr ] = useState(null);
    const [ isLoading, setLoading ] = useState(false)

    const syncClinicInfo = (e) => {
        e.preventDefault()
        setLoading(true);
        handleLogin(memberInfo).then( token => {
            fetchClinicInfo(memberInfo, token[0].data).then( clinic_attr => {
                setMemberInfo({
                    ...memberInfo,
                    ...clinic_attr
                })
                setLoading(false)
                if (clinic_attr === {}) { //not tested
                    setErr("The clinic id was not found in the sycle database")
                }
            })
        })  
    }

    return (
        <Fragment>
            { isLoading && <LoadingBar isLoading={isLoading} /> }
            { <button onClick={syncClinicInfo} className="c-btn"> Sync Clinic info with info from Sycle </button> }
            { err && <span> {err} </span> }
            { !memberInfo.street1 && <div> <span> The database does not contain any information on the clinic! Please fill out or sync manually </span> </div>}
            { 
                <Fragment>
                    <div className="c-form__form-group">
                        <label> address street 1 </label>
                        <input 
                            type="text" 
                            name="street1" 
                            className="form-control" 
                            onChange={handleInputChange} 
                            value={memberInfo.street1} 
                        />
                    </div>
                    <div className="c-form__form-group">
                        <label> address street 2 </label>
                        <input 
                            type="text" 
                            name="street2" 
                            className="form-control" 
                            onChange={handleInputChange} 
                            value={memberInfo.street2} 
                        />
                    </div>
                    <div className="c-form__form-group">
                        <label> City </label>
                        <input 
                            type="text" 
                            name="city" 
                            className="form-control" 
                            onChange={handleInputChange} 
                            value={memberInfo.city} 
                        />
                    </div>
                    <div className="c-form__form-group">
                        <label> State </label>
                        <input 
                            type="text" 
                            name="state" 
                            className="form-control" 
                            onChange={handleInputChange} 
                            value={memberInfo.state} 
                        />
                    </div>
                    <div className="c-form__form-group">
                        <label> Zip </label>
                        <input 
                            type="text" 
                            name="zip" 
                            className="form-control" 
                            onChange={handleInputChange} 
                            value={memberInfo.zip} 
                        />
                    </div>
                    <div className="c-form__form-group">
                        <label> Phone </label>
                        <input 
                            type="text" 
                            name="phone" 
                            className="form-control" 
                            onChange={handleInputChange} 
                            value={memberInfo.phone || memberInfo.phone1} 
                        />
                    </div>
                </Fragment>
            }


        </Fragment>
    )
}

export default SyncClinicAddress