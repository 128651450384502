import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import ModalWrapper from './modal/ModalWrapper';
import AddCustomer from './modal/AddCustomer';
import UpdateCustomer from './modal/UpdateCustomer';

class ManageCustomers extends Component {


    render() {
        return (
            <div className="o-wrapper">
                <div className="o-flex">
                    <h2>Customers</h2>
                    <ModalWrapper
                    title="Add Customer"
                    buttonText="Add Customer"
                    width={400}
                    showOk={false}
                    component={AddCustomer}
                    onAddCustomer={this.props.onAddCustomer}
                    />
                </div>
                <h3>Customer List</h3>
                <table className="c-table">
                    <thead>
                        <tr>
                            <th>Customer Name</th>
                            <th>Total Licenses</th>
                            <th>Expired Licenses</th>
                            <th>Manage Licenses</th>
                            <th>Update Customer</th>
                        </tr>
                    </thead>
                    <tbody>
                        { this.props.customers.map(customer => {
                            return (
                                <tr key={customer._id} >
                                    <td><div>{customer.name}</div></td>
                                    <td><div>{customer.licenses.length}</div></td>
                                    <td><div>{customer.licenses.length}</div></td>
                                    <td>
                                    <Link to={`/admin/customers/${customer._id}/licenses`}>Manage Licenses</Link>
                                    </td>
                                    <td>
                                        <ModalWrapper
                                        title="Update Customer"
                                        buttonText="Update Customer"
                                        width={400}
                                        showOk={false}
                                        component={UpdateCustomer}
                                        onDeleteCustomer={this.props.onDeleteCustomer} 
                                        onUpdateCustomer={this.props.onUpdateCustomer}
                                        customer={customer}
                                        />
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        )
    }
}

export default ManageCustomers;