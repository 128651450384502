import { combineReducers } from 'redux';
import customers from './customers';
import licenses from './licenses';
import members from './members';
import clinics from './clinics'
import auth from './auth';

export default combineReducers({
    customers,
    licenses,
    members,
    clinics,
    auth
})