import axios from 'axios';

/*
* Customer Actions
*/

export const ADD_CUSTOMER_SUCCESS = 'ADD_CUSTOMER_SUCCESS';
export const UPDATE_CUSTOMER_SUCCESS = 'UPDATE_CUSTOMER';
export const DELETE_CUSTOMER_SUCCESS = 'DELETE_CUSTOMER';
export const REQUEST_CUSTOMERS = 'REQUEST_CUSTOMERS';
export const FETCH_CUSTOMERS_SUCCESS = 'FETCH_CUSTOMERS_SUCCESS';
export const FETCH_CUSTOMERS_FAIL = 'FETCH_CUSTOMERS_FAIL';


/* Add Customer */
export function addCustomer(customer) {
    return function (dispatch) {
        
        const request = axios.post(process.env.REACT_APP_ENDPOINT_URL + '/v1/customers', customer);
          
        return request.then(response => {
            dispatch(addCustomerSuccess(response))
        },
        err => console.log(err)
        );
    }
}
function addCustomerSuccess(json) {
    return  { 
        type: ADD_CUSTOMER_SUCCESS, 
        customer: json.data.customer
    }
}


/* Update Customer */
export function updateCustomer(customer) {
    return function (dispatch) {
        
        const request = axios.patch(process.env.REACT_APP_ENDPOINT_URL + '/v1/customers', customer);
          
        return request.then(response => {
            
            dispatch(updateCustomerSuccess(response))
        },
        err => console.log(err)
        );
    }
}

function updateCustomerSuccess(json) {
    return  { 
        type: UPDATE_CUSTOMER_SUCCESS, 
        customer: json.data.customer,
        receivedAt: Date.now()
    }
}


/* Delete Customer */
export function deleteCustomer(id) {
    return function (dispatch) {
        
        const request = axios.delete(process.env.REACT_APP_ENDPOINT_URL + '/v1/customers/', {params: {_id: id}});
          
        return request.then(response => {
            dispatch(deleteCustomerSuccess(response.data.customer_id))
        },
        err => console.log(err)
        );
    }
}
function deleteCustomerSuccess(id) {
    return  { 
        type: DELETE_CUSTOMER_SUCCESS, 
        id: id,
        receivedAt: Date.now()
    }
}






/* Fetch Customer */
export function fetchCustomers() {
    return function (dispatch) {
     
        dispatch(requestCustomers())
        
        const request = axios.get(process.env.REACT_APP_ENDPOINT_URL + '/v1/customers');
          
        return request.then(response => {
            dispatch(fetchCustomersSuccess(response))
        },
        err => dispatch(fetchCustomersFail(err))
        );
    }
}
export function fetchCustomer(id) {
    return function (dispatch) {
     
        dispatch(requestCustomers())
        
        const request = axios.get(process.env.REACT_APP_ENDPOINT_URL + '/v1/customers/'+id);
          
        return request.then(response => {
            dispatch(fetchCustomersSuccess(response))
        },
        err => dispatch(fetchCustomersFail(err))
        );
    }
}

function requestCustomers() {
    return { 
        type: REQUEST_CUSTOMERS
    }
}
function fetchCustomersSuccess(json) {
    return {
        type: FETCH_CUSTOMERS_SUCCESS,
        customers: json.data.customers.map(customer => customer),
        receivedAt: Date.now()
    }
}

function fetchCustomersFail(json) {
    return {
        type: FETCH_CUSTOMERS_FAIL,
    }
}

