import React, { Fragment, useEffect, useRef, useState } from "react"
import {Typography, Button, Grid, Drawer, Checkbox, FormControlLabel} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import CloseIcon from '@material-ui/icons/Close';
import Divider from '@material-ui/core/Divider';
import Collapse from '@material-ui/core/Collapse';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FilterListIcon from '@material-ui/icons/FilterList';

const EXCLUDED_FIELDS = new Set([
    '_id', 
    '__v', 
    'createdAt', 
    'updatedAt', 
])

const CollapseSection = ({children, title, className, style, justify}) => {
    const [open, setOpen] = useState(false)
    return(
        <Grid container spacing={2}>
            <Grid item xs={12}> 
                <Button component="div" onClick={ () => setOpen(!open)} disableRipple={true} fullWidth={true} endIcon={open ? <ExpandLess /> : <ExpandMore />} color="primary"> 
                    <Typography variant="body1"> {title} </Typography> 
                  </Button>
                  <Divider />
            </Grid>
            <Grid item xs={12}>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <Grid container spacing={2} className={className} style={style} justify={justify}>
                        {children}
                    </Grid>
                </Collapse>
            </Grid>
        </Grid>
    )
}

const SelectField = ({label, onChange, options, filters, filterName}) => {
    const inputLabel = useRef(null);
    return (
        <FormControl variant="outlined" style={{"minWidth": "220px"}}>
            <InputLabel ref={inputLabel} htmlFor={filterName+"-type"}> {label} </InputLabel>
            <Select
                native
                value={filters[filterName].apply ? filters[filterName].value : null}
                onChange={(e) => onChange(filterName, e.target.value)}
                labelWidth={inputLabel.current ? inputLabel.current.offsetWidth : '0'}
                inputProps={{
                    name: filterName,
                    id: filterName+'-type',
                }}
            >
                {options.map( value => value.label ? <option value={value.value}>{value.label}</option> : <option value={value}>{value}</option>)}
            </Select>
        </FormControl>
    )
}

const SchedulerFilter = ({filters, onSelectFilter, members}) => {
    const schedluer_types = [
        {label: 'All', value: 'all'},
        {label: 'Sycle Classicc', value: '/v1/'},
        {label: 'Sycle Pro', value: '/v2/'},
        {label: 'Blueprint', value: '/blueprint/'},
    ]
    const [dynamicFields, setFields] = useState({
        endpoints: ['all'],
        logins: ['all']
    })


    useEffect( () => {
        let endpoints = dynamicFields.endpoints
        let logins = dynamicFields.logins
        members.map(({api_endpoint, username}) => {
            if(!endpoints.includes(api_endpoint)) {
                endpoints.push(api_endpoint)
            }
            if(!logins.includes(username)) {
                logins.push(username)
            }
        })
        setFields({endpoints, logins})
    }, [members])

    return(
        <CollapseSection title="Scheduler Filters">
            <Grid item xs={12}>
                <SelectField label="Scheduler Type" onChange={onSelectFilter}  options={schedluer_types} filters={filters} filterName='nextslot_endpoint' />
            </Grid>
            <Grid item xs={12}>
                <SelectField label="Endpoints Type" onChange={onSelectFilter}  options={dynamicFields.endpoints} filters={filters} filterName='api_endpoint' />
            </Grid>
            <Grid item xs={12}>
                <SelectField label="Logins " onChange={onSelectFilter}  options={dynamicFields.logins} filters={filters} filterName='username' />
            </Grid>
        </CollapseSection>
    )
}

const LicensesFilter = ({filters, onSelectFilter}) => {

    const options = [
        {label: 'All', value: 'all'},
        {label: 'Active', value: 'active'},
        {label: 'Not Licensed', value: 'non_active'},
    ]
    return (
        <CollapseSection title="License Filter">
            <Grid item xs={12}>
                <SelectField label="License Type" onChange={onSelectFilter} options={options} filters={filters} filterName="license" />
            </Grid>
        </CollapseSection>
    )
}

const FieldSelections = ({title, fields, filters, filterName, onChange}) => {
    return(
        <CollapseSection title={title} style={{"maxHeight": "350px", "overflowY": "scroll"}}>
            {fields.map(field => 
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={filters[filterName].value.includes(field)}
                                onChange={() => onChange(filterName, field)}
                                value={field}
                                inputProps={{'aria-label': field+' checkbox'}}
                            />
                        }
                        label={field}
                    />
                </Grid>
           )}
        </CollapseSection>
    )
}

const MemberFilterDrawer = ({ members, setMembers}) => {
  const [open, setOpen] = useState(false)
    const default_filters = {
        username: { apply: false, value: null},
        api_endpoint: { apply: false, value: null},
        nextslot_endpoint: {apply: false, value: null},
        empty_fields: {apply: false, value: []},
        not_empty_fields: {apply: false, value: []},
        license: { apply: false, value: null}
    }
    const [filters, setFilters] = useState(default_filters)
    let  fields = members.length === 0 ? [] : Object.entries(members[0]).map( ([k, v]) => k).filter( v => !EXCLUDED_FIELDS.has(v))

    const onChangeFilter = (name, value) => {
        if(name === 'empty_fields' || name === 'not_empty_fields') {
            let current_values = filters[name].value
            if(current_values.includes(value)) {
             current_values = current_values.filter(v => v !== value) 
            } else {
                 current_values.push(value)
            }
            setFilters({...filters, [name]: { apply: true, value: current_values}})
        } else {
            setFilters({...filters, [name]: { apply: true, value}})
        }
    }

    const selectionFilters = {
        empty_fields: (arr, field) => arr.filter(member => member[field] === '' || member[field] === null || member[field] === undefined),
        not_empty_fields: (arr, field) =>  arr.filter(member => member[field] !== '' && member[field] !== null && member[field] !== undefined)
    }

    const onApplyFilter = () => {
        let filtered_list = members
        Object.entries(filters).map( ([name, {apply, value} ]) => {
            if(apply) {
                if(name==="empty_fields" || name==="not_empty_fields") {
                    value.map(field => {
                        filtered_list = selectionFilters[name](filtered_list, field)
                    })
                } else if(name==='license') {
                    filtered_list = filtered_list.filter(member => value === 'all' ? true : value === 'active' ? member[name] !== undefined : member[name] === undefined)
                } else {
                    filtered_list = filtered_list.filter( member => value === 'all' ? true : member[name] === value)
                }
            }
        })
        setMembers(filtered_list)
        setOpen(false)
    }

    const onResetFilters = () => {
        setMembers(members)
        setFilters(default_filters)
        setOpen(false)
    }

    return(
        <Fragment>
          <Button onClick={() => setOpen(true)} variant="outlined" startIcon={<FilterListIcon />}> Filters </Button>
          <Drawer anchor="right" open={open} variant="temporary" >
              <Grid container style={{padding: "1rem", width: "19rem", height: "100%"}} justify="space-around" alignContent="space-between">
                  <Grid item xs={12}>
                      <Grid container>
                          <Grid item xs={12}> <Button onClick={() => setOpen(false)} color="primary" startIcon={<CloseIcon />}> Close </Button> </Grid>
                          <Grid item xs={12}> <SchedulerFilter filters={filters} onSelectFilter={onChangeFilter} members={members} /></Grid>
                          <Grid item xs={12}> <FieldSelections title="Empty Fields Filter" filters={filters} onChange={onChangeFilter} fields={fields} filterName="empty_fields" /></Grid>
                          <Grid item xs={12}> <FieldSelections title="Not Empty Fields Filter" filters={filters} onChange={onChangeFilter} fields={fields} filterName="not_empty_fields" /></Grid>
                          <Grid item xs={12}> <LicensesFilter filters={filters} onSelectFilter={onChangeFilter} /></Grid>
                      </Grid>
                  </Grid>
                  <Grid item>
                      <Grid container spacing={3} alignItems="center">
                          <Grid item> <Button onClick={onResetFilters} variant="contained" > Clear </Button> </Grid>
                          <Grid item> <Button onClick={onApplyFilter} variant="contained"> Apply </Button> </Grid>
                      </Grid>
                  </Grid>

              </Grid>
          </Drawer>
        </Fragment>
    )
}

export default MemberFilterDrawer

export { CollapseSection }
