import { connect } from 'react-redux'
import { authenticate } from '../actions/auth'
import LogInPage from '../components/LogInPage';


const mapStateToProps = state => ({
    auth: state.auth
})
  
const mapDispatchToProps = dispatch => ({
    onLogin: credentials => dispatch(authenticate(credentials))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LogInPage)
