import React from 'react';

class DeleteLicense extends React.Component {

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.onDeleteLicense(this.props.license._id, this.props.license.customer_id);
        this.props.onCloseModal();
    }
    

    render(props) {
        return (
            <div>
                <ul className="c-underline-row">
                        <li><strong>License ID:</strong> {this.props.license._id}</li>
                    </ul> 
                
                <button type="submit" onClick={this.handleSubmit} className="c-btn">Delete</button>
            </div>
        );
    }
}

export default DeleteLicense;