import React from 'react';

class AddLicenses extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            customerInfo: {
                customer_id: this.props.customerID,
                licenses: '',
            }
        };
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.onAddLicenses(this.state.customerInfo);
        this.props.onCloseModal();
    }

    handleInputChange = (e) => {
        e.preventDefault();
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState(prevState => ({
            customerInfo: {
                ...prevState.customerInfo,
                [name]: value
            }
        }))
    }

    render(props) {
        return (
            <form onSubmit={this.handleSubmit} className="c-form">
                <div className="c-form__form-group">
                    <label>How Many Licenses to Add?</label>
                    <input 
                        type="number" 
                        name="licenses" 
                        className="form-control" 
                        onChange={this.handleInputChange} 
                        value={this.state.customerInfo.licenses} 
                    />
                </div>
                <div className="c-form__controls">
                    <button type="submit" className="c-btn">Create</button>
                </div>
            </form>
            
        );
    }
}

export default AddLicenses;