import React from 'react';

const Modal = (props) => {
    const {children, onClick, title, onClose, component : Component } = props
    return(
        <div onClick={onClick ? onClick : null} className="c-modal__bg">
            <div className="c-modal">
                <header className="c-modal__header o-flex o-flex--center">
                    <h2>{title}</h2>
                    <button onClick={onClose} className="c-btn">Close</button>
                </header>       
                {Component ? <Component onCloseModal={onClose} {...props} />  : null}
                {children}
            </div>  
        </div>
    )
}

class ModalWrapper extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showModal: false
        }
    }

    // handleBackgroundClick =  (e) => {
    //     if (e.target === e.currentTarget) this.closeModal();
    // };

    openModal = (e) => {
        this.setState({showModal: true});
    }

    closeModal = (e) => {
        this.setState({showModal: false});
    }

    render(props) {
        const {component: Component} = this.props;
        return (
            <div onClick={this.handleBackgroundClick} className="c-modal__wrap">
                <button className="c-btn" onClick={this.openModal}>{this.props.buttonText}</button>
                {this.state.showModal && 
                    <Modal title={this.props.title} onClick={this.handleBackgroundClick} onClose={this.closeModal} component={Component} {...this.props} />
                }
            </div>
        );
    }
};


ModalWrapper.defaultProps = {
    title: '',
    showOk: true,
    okText: 'OK',
    okDisabled: false,
    width: 400,
    onOk: () => {}
};

export default ModalWrapper;
export {
    Modal
}