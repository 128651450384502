import React, { Component } from 'react';
import { connect } from 'react-redux';
import { 
    addCustomer, 
    deleteCustomer, 
    updateCustomer, 
    fetchCustomers 
} from '../actions/customer';
import { fetchLicenses } from '../actions/license';
import ManageCustomers from '../components/ManageCustomers';

class CustomerList extends Component {

    componentWillMount() {
        this.props.onFetchCustomers();
        this.props.onFetchLicenses();
    }

    render() {

        if(this.props.customers) {
            return (
                <ManageCustomers {...this.props} ></ManageCustomers>
            )
        } else {
            return (
                'Loading...'
            )
        }

    }
}


const mapStateToProps = state => {
    let customerMap = [];
    let returnCustomers = [...state.customers.items]
    for( let i = 0; i < returnCustomers.length; i++) {
        customerMap[returnCustomers[i]._id] = i;
        returnCustomers[i].licenses = [];
    }
    for( let i = 0; i < state.licenses.items.length; i++) {
        returnCustomers[customerMap[state.licenses.items[i].customer_id]].licenses.push(state.licenses.items[i]);
    }
    return {
        customers: returnCustomers
    }
}
  
const mapDispatchToProps = dispatch => ({
    onAddCustomer: customer => dispatch(addCustomer(customer)),
    onUpdateCustomer: customer => dispatch(updateCustomer(customer)),
    onDeleteCustomer: id => dispatch(deleteCustomer(id)),
    onFetchCustomers: () => dispatch(fetchCustomers()),
    onFetchLicenses: () => dispatch(fetchLicenses())
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CustomerList)