import { useContext, useEffect, useState } from 'react'
import StateContext from '../components/MemberContext'
import axios from "axios"

const handleLogin = memberInfo => {
  let params = {
    params: {
      member: memberInfo.username,
      pass: memberInfo.password,
      type: memberInfo.nextslot_endpoint,
      api_endpoint: memberInfo.api_endpoint
    }
  }
  return axios.get(process.env.REACT_APP_ENDPOINT_URL + '/v2/login', params)
}

const handleMultipleLogins = async(members) => {
}

const useFetchLoginToken = ({memberInfo})  => {
  const { token, tokens } = useContext(StateContext)
  const [error, setErr] = useState(null)
  const [fetching, setFetching] = useState(false)
  const [fetchedToken, setToken] = useState({
    token: null,
    tokens: null,
    fetching: true
  })


  /***************************************
   * This will save multiple tokens for
   * different login types
  ***************************************/
  const fetchMultipleTokens = async(members) => {
    let multiple_tokens = {}
    for (const member of members) {
      if(!multiple_tokens[member.username]) {
        let token_data = await handleLogin(member)
        multiple_tokens[member.username] = token_data.data
      }
    }
    setToken({...fetchedToken, tokens: multiple_tokens})
  }

  useEffect( () => {
    /******************************************
     * A Token is not currently being fetched 
     * Have the hook fetch its own token
     ******************************************/
    if(!memberInfo ) {
      console.log("nothing to fetch")
    }else if (!token) {
      setFetching(true)
      setErr(null)
      handleLogin(memberInfo).then( res => {
        let { token, token_type, token_key} = res.data
        setFetching(false)
        setToken({
          token,
          token_type,
          token_key,
          fetching: false
        })
      }).catch( err => {
        setFetching(false)
        setToken({
          ...token,
          fetching: false
        })
        console.warn("Error Fetching token. Error message provided: ", err)
        setErr("There was an error fetching the token. Please check the logs")
      })
    } else if(token.fetching) {
      setFetching(true)
    }
  }, [])

  /*********************************
   * If a token is being fetched, set loader
   *********************************/
  useEffect( () => {
    if(token && !token.fetching) {
      setFetching(false)
    }
  }, [token])

  
  return {error, token: token ? token : fetchedToken, fetching, fetchMultipleTokens }
}

export default useFetchLoginToken
