import React, {Fragment, useState, useContext } from "react"
import {Button, Typography, Grid } from '@material-ui/core'
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import { MemberActionContext } from '../containers/MemberList'

/****************************************** 
 * These fields are included in member object
 * we dont want these.
 * TODO remove from response in api
******************************************/
const EXCLUDED_FIELDS = new Set([
  '_id', 
  '__v', 
  'license',
  'createdAt', 
  'updatedAt', 
  'license_id', 
])

const UpdateMembersDrawer = ({members, closeDrawer}) => {
  const [open, setOpen] = useState(false)
  const [ selectedFields, setSelected ] = useState([])
  const [ fieldValues, setFieldValues] = useState({})
  const { onUpdateMember } = useContext(MemberActionContext)

  let  fields = members.length === 0 ? [] : Object.entries(members[0]).map( ([k, v]) => k).filter( v => !EXCLUDED_FIELDS.has(v))

  const onSelectValue = (e) => {
    let fieldName = e.target.value
    setSelected([...selectedFields, fieldName])
    setFieldValues({...fieldValues, [fieldName]: '' })
  }

  const onChangeFieldValue = (e, field) => {
    setFieldValues({...fieldValues, [field]: e.target.value})
  }

  const onCloseDrawer = () =>{
    closeDrawer()
    setSelected([])
    setFieldValues({})
  }

  const onBulkUpdate = () => {
    members.map(member => {
      let updated_membr = member
      selectedFields.map( fieldName => {
        updated_membr[fieldName] = fieldValues[fieldName]
      })
      debugger
      onUpdateMember(updated_membr)
    })
    onCloseDrawer()
  }

  return(
    <Fragment>
      <Button color="primary" onClick={() => setOpen(true)}>Update</Button>
    <Drawer anchor="left" open={open} variant="persistent" >
      <Grid container style={{padding: "1rem", width: "20rem", height: "100%"}} justify="space-around" alignContent="space-between">
        <Grid item>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="body1"> Updating {members.length} {members.length === 1 ? "Member" : "Members" }</Typography>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="caption" style={{color: "grey"}}> Fields </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="outlined" >
                  <Select
                      id="field_section"
                      value={selectedFields}
                      onChange={onSelectValue}
                      renderValue={selected => {console.log("SELECTED IS", selected); return(
                        <div >
                          {selectedFields.map(value => 
                            <Chip key={value} label={value} style={{display: 'flex', flexWrap: 'wrap'}}/>
                          )}
                        </div>)}
                      }
                  >
                      {fields.map(field => <MenuItem value={field}>{field}</MenuItem>)}
                  </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
                  {selectedFields.map( field => 
                      <Grid container>
                        <Grid item xs={12}> <Typography variant="body1"> {field} </Typography> </Grid>
                        <Grid item xs={12}> 
                          <TextField id="outlined-basic"  variant="outlined" onChange={(e) => onChangeFieldValue(e, field)} value={fieldValues[field]} />
                        </Grid>
                      </Grid>
                  )}
            </Grid>
          </Grid>
        </Grid>
        <Grid style={{paddingTop: "20px"}}>
          <Grid container justify="center" alignItems="center" spacing={3}>
            <Grid item> <Button variant="contained" onClick={onBulkUpdate}>Update</Button></Grid>
            <Grid item> <Button variant="contained" onClick={() => setOpen(false)}>Close</Button></Grid>
          </Grid>
        </Grid>
      </Grid>
    </Drawer>
    </Fragment>
  )
}

export default UpdateMembersDrawer
