import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchClinics }from '../actions/clinic'
import axios from "axios"

const fetchClinicInfo = async(memberInfo, token, getName, getApptTypeInfo) => {
  let login_keys = token
  let params = {
      params: {
          token_type: login_keys.token_type,
          token_key: login_keys.token_key,
          token: login_keys.token,
          parent_company_id: memberInfo.parent_company_id,
          clinic_id: [memberInfo.clinic_id],
          api_endpoint: memberInfo.api_endpoint
      }
  }
  let appt_info = null
  if(getApptTypeInfo && memberInfo.nextslot_endpoint === '/v2/') {
      params.params.clinic_id = memberInfo.clinic_id
      appt_info  = await axios.get(process.env.REACT_APP_ENDPOINT_URL + '/v2/auth_appointment_types', params)
      appt_info = appt_info.data.appointment_types
      params.params.clinic_id = [memberInfo.clinic_id]
  }
  let endpoint = process.env.REACT_APP_ENDPOINT_URL + '' + memberInfo.nextslot_endpoint + 'clinic_info'
  let clinic_res = await axios.get(endpoint, params)
  let clinic = clinic_res.data.data ? clinic_res.data.data[0] : clinic_res.data.clinic_details[0]
  let clinic_info = clinic.clinic || clinic
  let clinic_obj = { ...clinic_info.address, "phone": clinic_info.phone ? clinic_info.phone : clinic_info.phone1 }
  if(getName)
      clinic_obj['clinic_name'] = clinic_info.name || clinic_info.clinic_name
  if(getApptTypeInfo) {
    clinic_obj['appt_types'] = appt_info || clinic.appointment_types
  }
  return clinic_obj
}

const useClinicData = (members, tokens, member) => {
  const isLoading = useSelector(state => state.clinics.isFetching)
  const clinics = useSelector(state => state.clinics.items)
  const dispatch  = useDispatch()

  const getClinicInfo = async(member, type, token) => {
    let info = await fetchClinicInfo(member, token, type === 'all' || type === 'name')
    if(type === 'name')
      info = {clinic_name: info.clinic_name}
    return info
  }

  const getApptTypes = (clinic_id) => {
    if(clinic_id === 'all')
      return [...Object.values(clinics[1]).map(v => v)].flat(1)
    return clinics[1][clinic_id]
  }
  useEffect( () => {
    if(members && clinics.length === 0 && !isLoading && tokens && tokens.tokens ) {
      console.log("dispatched")
      dispatch(fetchClinics(members, tokens.tokens))
    }  
  }, [members, tokens, isLoading, clinics])

   return {clinicsLoading: clinics.length === 0 ? true : isLoading, getApptTypes, getClinicInfo}
}

export default useClinicData
export { 
  fetchClinicInfo
}