import React, { Component } from 'react';
import { connect } from 'react-redux'
import { addLicenses, deleteLicense, fetchCustomerLicenses } from '../actions/license'
import { fetchCustomer } from '../actions/customer'
import ManageLicenses from '../components/ManageLicenses';


class LicenseList extends Component {

    componentWillMount() {
        this.props.onFetchCustomer(this.props.customerID);
        this.props.onFetchCustomerLicenses(this.props.customerID);
    }

    render() {

        if(this.props.customer) {
            return (
                <ManageLicenses {...this.props} ></ManageLicenses>
            )
        } else {
            return (
                'Loading...'
            )
        }
    }
}


const mapStateToProps = (state, ownProps) => {


    const currentCustomer = state.customers.items.filter(customer => 
        (customer._id === ownProps.match.params.id)
    );
    const customerLicenses = state.licenses.items.filter(license =>
        (license.customer_id === ownProps.match.params.id)
    );
    const returnCustomer = {
        ...currentCustomer[0],
        licenses: [...customerLicenses]
    }
    
    return {
        customerID: ownProps.match.params.id,
        customer: returnCustomer
    }
}
  
const mapDispatchToProps = dispatch => ({
    onAddLicenses: customer => dispatch(addLicenses(customer)),
    onDeleteLicense: (id, customer_id) => dispatch(deleteLicense(id, customer_id)),
    onFetchCustomerLicenses: id => dispatch(fetchCustomerLicenses(id)),
    onFetchCustomer: id => dispatch(fetchCustomer(id))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LicenseList)
