import { 
    ADD_LICENSES_SUCCESS,
    FETCH_LICENSES_SUCCESS,
    DELETE_LICENSE_SUCCESS
} from '../actions/license';



const licenses = (
    state = {
        items: [],
        lastUpdated: ''
    }, 
    action
) => {
    switch (action.type) {

        case ADD_LICENSES_SUCCESS:
            return {
                items: [...state.items, ...action.licenses],
                lastUpdated: action.receivedAt
            }

        case FETCH_LICENSES_SUCCESS:
            return {
                items: action.licenses,
                lastUpdated: action.receivedAt
            }
        
        case DELETE_LICENSE_SUCCESS:
            return {
                items: state.items.filter( license => (
                    (action.license_id !== license._id)
                )),
                lastUpdated: action.receivedAt
            }
            
        default:
            return state
    }
}
export default licenses